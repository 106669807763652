.tag-selector {
	margin: 50px 0;
	padding: 15px;
	&__dropdowns-container {
		display: flex;
	}
	&__header-container {
		width: 100%;
		border-bottom: 2px solid black;
		display: flex;
		justify-content: space-between;
		.title {
			font-size: 1.4rem;
			font-family: 'Montserrat';
			color: #000;
			letter-spacing: 0.05rem;
			font-weight: bold;
			margin: 5px 0;
		}
		&:hover {
			cursor: pointer;
		}
	}
}
