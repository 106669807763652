.dropdown-preview__container {
	margin: 3rem 0;
	.MuiInputBase-root {
		width: 100%;
		.MuiSelect-root {
			background-color: $white;
		}
		.MuiSelect-selectMenu {
			padding: 1em;
			height: 1em;
			border: 0.1rem solid $gray;
		}
	}
	.dropdown-preview-value {
		@include normal-text($light-black);
		line-height: 1em;
	}
	.fa-check-circle {
		.green {
			color: $green;
		}
		.gray {
			color: $gray;
		}
	}
}
