.folder-modal {
	background-color: transparent;
	border: none;
	max-width: 50rem;
	margin: 8rem auto;
	&__content {
		left: unset !important;
		right: unset !important;
		border-radius: $small-border-radius;
		@include gray-border();
		position: relative;
		background-color: transparent;
		max-width: 50rem;
		padding: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: $white;
		&__title {
			font-family: $montserrat;
			text-align: left !important;
			text-transform: none;
			margin-bottom: 2rem;
			font-weight: 600;
		}
		&__parent-folder-title {
			font-size: $normal-font-size;
			font-family: $montserrat;
			color: $light-black;
			text-align: left;
			text-transform: none;
			margin: 1rem 0;
			font-weight: 500;
		}
		&__parent-folder-select {
			width: 100%;
			margin: 1rem 0;
		}
	}
}
