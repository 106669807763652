.button {
	@include button-container-default;
	button {
		@include button-default($dark-blue);

		&.button-primary {
			background-color: $orange;
		}

		&.button-cancel {
			background-color: $gray;
		}
	}

	&-text {
		@include header-2-text($white);
		text-transform: none;
		display: flex;
		padding: 0.9rem 2rem;
		text-align: center;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		&-inside {
			font-size: inherit;
			font-family: inherit;
			font-weight: inherit;
		}
		.icon {
			margin-left: 0.5em;
			&.icon-start {
				margin-right: 0.5em;
				margin-left: unset;
			}
		}
		&.button-text-small {
			font-size: $small-font-size;
			font-family: $cabin;
			font-weight: bold;
			padding: 0.6rem 2rem;
		}

		&.button-text-normal {
			font-size: $normal-biggest-font-size;
			font-weight: bold;
			padding: 0.9rem 2rem;
		}
	}

	& .button-transparent {
		background-color: transparent;
	}

	& .button-primary {
		background-color: $orange;
	}
	& .button-cancel {
		background-color: $gray;
	}

	& .button-primary,
	& .button-cancel {
		.button-text {
			font-family: $cabin;
			font-size: $small-font-size;
			min-width: 12rem;
		}
	}

	& .button-small {
		.button-text {
			font-size: $small-smaller-font-size;
			min-width: 10rem;
			padding: 0.5rem 1rem;
		}
	}

	button:disabled {
		@include grayscale;
	}
}
