.section-toolbar {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	border-top: 0.1rem solid $gray;
	align-items: center;
	padding: 1.9rem 0;
	&-column {
		display: flex;
		flex: 0 1 33.3%;
		max-width: 33.3%;
		margin-bottom: 1rem;
		@include breakpoint(desktop) {
			flex: 0 1 16%;
			max-width: 16%;
		}
	}
	&-button {
		display: flex;
		flex: 0 1 33.3%;
		margin-bottom: 1rem;
		max-width: 33.3%;
		@include breakpoint(desktop) {
			flex: 0 1 20%;
			max-width: 20%;
		}
		padding: 0 1rem;
		align-items: center;
		&:hover {
			cursor: pointer;
			transition: $transition-default;
		}
		&-text {
			@include normal-text();
		}
	}
	.icon {
		margin-right: 1rem;
		color: $gray;
	}
	.separator {
		width: 0.1rem;
		height: 3.5rem;
		background-color: $gray;
	}
	.fa-plus-circle {
		color: $green;
		&.inactive {
			color: $gray;
		}
	}
	.fa-copy,
	.fa-sticky-note {
		color: $dark-blue;
		&.inactive {
			color: $gray;
		}
	}
	.fa-trash {
		color: $red;
		&.inactive {
			color: $gray;
		}
	}
}
