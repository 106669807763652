.mailing-list-select {
	padding: 2rem 8rem 2rem 0;
	.MuiInputBase-root {
		width: 100%;
	}
	.MuiSelect-root {
		display: flex;
		align-items: center;
	}
}
.dashboard-item {
	.button-add {
		background-color: $orange;
	}
	.button-big {
		width: 20rem;
	}
	.button-small {
		width: 14rem;
	}
	.button-big-text {
		@include normal-bold-text($white);
	}
	.button-small-text {
		@include smaller-text($white);
	}
	.button-cancel {
		background-color: $gray;
	}
	.label {
		width: 100%;
		&-text {
			@include normal-text($black);
			margin-bottom: 2rem;
		}
	}
	.clear-button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem;
		width: 3em;
		height: 3em;
		z-index: 100;
		display: flex;
		justify-content: flex-end;
	}
	.button-wrapper {
		margin-top: 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		.button-right {
			margin-left: 0;
			margin-top: 1rem;
		}
		@include breakpoint(tab-port) {
			flex-direction: row;
			.button-right {
				margin-top: 0;
				margin-left: 1.5rem;
			}
		}
	}
}

ul.MuiList-padding {
	padding-top: 3rem;
}
.select-value-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fa-check-circle {
	&.green {
		color: $green;
	}
	&.gray {
		color: $gray;
	}
}
