div.linear-scale__container {
	padding: $template-padding;
	.linear-scale-upper-row {
		display: flex;
		align-items: center;
		padding-bottom: 2rem;
		.fa-minus {
			color: $black;
			padding: 0 1rem;
		}
		.linear-scale-switch {
			display: flex;
			align-items: center;
			margin-left: 10%;
			&-text {
				@include normal-text($black);
			}
		}
	}
	.linear-scale-lower-row {
		display: flex;
		align-items: center;
		padding-bottom: 1rem;
		position: relative;
		.linear-scale-input {
			border-bottom: 0.1rem solid $gray;
			height: 2em;
			border-radius: 0;
			&-container {
				width: 50%;
			}
		}
		.linear-scale-number {
			@include normal-bold-text($orange);
			margin-right: 1rem;
			width: 2em;
		}
	}
}
