.age-filter {
	margin-top: 1.2rem;
	&__slider {
		margin-top: 4rem;
		.MuiSlider-root {
			width: 80%;
			padding: 10%;
		}
	}
	&__empty-text {
		margin-top: 2rem;
	}
}
