.category__container {
	padding: 2rem;
	min-height: 80px;
	background-color: $white;
	flex-direction: column;
	position: relative;
	flex: 1 1 100%;
	max-width: 100%;
	margin-bottom: 1.6rem;
	border-radius: $small-border-radius;
	@include gray-border();
	@include breakpoint(tab-port) {
		flex: 0 1 49%;
		max-width: 49%;
		margin-right: 2%;
		&:nth-child(even) {
			margin-right: 0;
		}
	}
	@include breakpoint(desktop) {
		flex: 0 1 24.25%;
		max-width: 24.25%;
		margin-right: 1%;
		&:nth-child(even) {
			margin-right: 1%;
		}
		&:nth-child(3n) {
			margin-right: 1%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
	&:hover {
		cursor: pointer;
	}
	@include box-shadow-default();

	&-disabled {
		&:hover {
			cursor: default;
		}
	}
	.icon-container {
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 2.2rem;
		height: 2.2rem;
		.fa-question-circle {
			color: $orange;
		}
	}

	.open {
		animation: {
			name: slide-down;
			duration: 300ms;
			timing-function: ease-in;
			fill-mode: forwards;
		}
	}
	.close {
		animation: {
			name: slide-up;
			duration: 200ms;
			timing-function: ease-in;
			fill-mode: forwards;
		}
	}

	.category-title {
		margin-top: 0.5rem;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		font-size: $bigger-font-size;
		gap: 2rem;
		width: 100%;
		&-text {
			font-weight: bold;
			text-align: left;
			white-space: pre-line;
			&.active {
				color: $orange;
			}
		}
		.fa-check-circle {
			color: $orange;
			margin-left: 0.5rem;
			font-size: 3rem;
		}
		&-inactive {
			display: flex;
			&:hover {
				cursor: pointer;
			}
		}
		&-disabled {
			display: flex;
			height: 2em;
		}
		&-closed {
			height: 100%;
		}
	}
	&-active {
		border: 1px solid $orange;
		background-color: $lighter-orange;
		&.category-title {
			&-text {
				color: $orange;
			}
		}
	}
	.category-detail {
		margin-top: 2.4em;
		max-height: 100%;
		overflow: hidden;
		&-description {
			font-size: $normal-biggest-font-size;
			padding-bottom: 10rem;
			text-align: left;
			word-break: break-word;
			hyphens: auto;
			color: $medium-gray;
		}
		&-open {
			animation: {
				name: slide-down;
				duration: 300ms;
				timing-function: ease-in;
				fill-mode: forwards;
			}
		}
		&-close {
			max-height: 0;
		}
		&-button {
			background-color: $orange;
			position: absolute;
			bottom: 2rem;
			left: 10%;
		}
	}
}

@keyframes slide-up {
	from {
		max-height: 100%;
	}
	to {
		max-height: 0;
	}
}

@keyframes slide-down {
	from {
		max-height: 0;
	}
	to {
		max-height: 100%;
	}
}
