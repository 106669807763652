.recipient-list__container {
	.recipient-list-header {
		@include container-padding-small(1rem);
		div.header-left {
			display: flex;
			align-items: center;
			.header-back-button {
				display: flex;
				align-items: center;
				&:hover {
					cursor: pointer;
				}
				.fa-long-arrow-alt-left {
					margin-right: 1rem;
				}
			}
			.header-text {
				@include header-3-text($medium-gray);
				margin-right: 1.5rem;
			}

			.header-separator {
				background-color: $dark-gray;
				width: 0.2rem;
				height: 3rem;
				margin-right: 1.5rem;
			}
		}
	}

	.recipient-list-inner-container {
		@include container-padding-small(3rem);
		.recipient-list-warning {
			min-height: 76px;
			&.show {
				display: flex;
				align-items: center;
				visibility: visible;
			}
			&.hide {
				visibility: hidden;
			}
			padding: 2rem;
			background-color: $white;
			margin-bottom: 3rem;
			display: flex;
			justify-content: space-between;
			&-text {
				@include header-2-text($orange);
			}
			&-save-button {
				background-color: $orange;
				&-text {
					padding: 0.6rem 3rem;
				}
			}
		}
		background-color: $darker-white;
		.recipient-list-content {
			background-color: $white;
			@include box-shadow-default(0, 0.4rem, 2rem, 0, rgba(0, 0, 0, 0.05));
			&.add-multiple {
				margin-bottom: 2rem;
			}
		}
	}
}
