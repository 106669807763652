.background-with-title {
	display: flex;
	margin-top: -6rem;
	justify-content: center;
	align-items: center;
	background: url('../assets/images/main-background.png') center;
	background-blend-mode: multiply;
	background-size: cover;
	width: 100%;
	max-height: 50%;
	min-height: 30rem;
	&-text {
		font-size: $biggest-title-font-size;
		color: $white;
		text-align: center;
	}
}
