%container-padding {
	padding: 3rem 7rem;
}
.form-category__container {
	.input-container {
		display: flex;
		flex-direction: column;
		background-color: $white;
		@extend %container-padding;
		.input-title {
			@include header-2-text($light-black);
			margin-bottom: 1rem;
		}
		.input-sub-title {
			@include normal-text($black);
			margin-bottom: 2rem;
		}
		.input {
			@include normal-bold-text();
			border-color: $light-black !important;
			&:focus {
				border-color: $orange !important;
			}
		}
	}
	.info-container {
		display: flex;
		flex-direction: column;
		background-color: $white;
		position: relative;
		@extend %container-padding;
		margin-top: 1.5rem;
		.info-title {
			@include header-2-text($light-black);
			margin-bottom: 1rem;
		}
		.info-sub-title {
			margin: 1em 0;
			@include normal-text();
		}
	}
	.category-container {
		display: flex;
		flex-direction: column;
		background-color: $white;
		position: relative;
		@extend %container-padding;
		margin-top: 1.5rem;
		.category-title {
			@include header-2-text($light-black);
			margin-bottom: 1rem;
		}
		.category-guide {
			@include normal-text();
			white-space: pre-line;
			margin-bottom: 1.5rem;
		}
		.category-sub-title {
			margin: 1em 0;
			@include normal-bold-text();
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
		}
		.icon-container {
			margin-left: 0.5rem;
			padding: 0.5rem;
			position: relative;
			.fa-question-circle {
				color: $orange;
			}
		}
		.button-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-top: 2rem;
			.button-back {
				background-color: $dark-blue;
			}
			.button-next {
				background-color: $orange;
				&-disabled {
					@include button-disabled-default();
				}
			}
		}
		.button-container-single {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-top: 2rem;
			position: relative;
			.button-back {
				background-color: $dark-blue;
			}
			.button-next {
				background-color: $orange;
				&-disabled {
					@include button-disabled-default();
				}
			}
		}
	}
}
