.ellipsis {
	padding: 1rem 1.6rem;
	page-break-inside: avoid;
	&-content {
		display: flex;
	}
	&-text {
		@include normal-text($black);
		display: flex;
		overflow: hidden;
		text-overflow: ellipsis;
		max-height: 4.5em;
		word-break: break-word;
		position: relative;
		flex: 1;
		&-open {
			display: flex;
			flex: 1;
			max-height: 100%;
		}
		//&::before {
		//    position: absolute;
		//    content: "...";
		//    bottom: 0;
		//    right: 1.6rem;
		//}
	}
	&-order-text {
		width: 2em;
		@include normal-text($orange);
	}
	&-button {
		margin-top: 0.5rem;
		padding: 1em;
		margin-left: -1em;
		outline: none;
		&:hover {
			opacity: 0.7;
		}
		span {
			@include normal-text($orange);
		}
	}
}
