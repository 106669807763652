.dashboard-item {
	display: flex;
	flex-direction: column;
	padding: 2rem 2rem 2rem 0;
	margin-bottom: 1.5rem;
	background-color: $white;
	&__inner {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
	&__dark-blue-border {
		border-color: $dark-blue !important;
	}
	.done {
		color: $green;
	}
	.undone {
		color: $gray;
	}
	.icon {
		padding: 0 3rem;
		margin-top: 0.5rem;
	}
	.edit-button {
		border-radius: $small-border-radius;
		background-color: $orange;
		&-container {
			width: 10rem;
		}
		&-disabled {
			background-color: $gray;
			&:hover {
				opacity: 1;
				cursor: not-allowed;
			}
		}
	}
	button .edit-button-text {
		@include smaller-text($white);
		font-weight: bold;
		padding: 0.6rem 1.9rem;
	}

	.content {
		flex: 1;
		width: 100%;
		overflow: hidden;
		.title {
			@include header-2-text($black);
			margin-bottom: 1.5rem;
		}
	}
}
