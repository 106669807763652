.radio-template {
	padding: $template-padding;
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		.radio-switch {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
		.preview-button {
			padding: 1rem 0 1rem 1rem;
			&-text {
				color: $orange;
			}
		}
	}
}
