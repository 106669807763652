.order-list {
	list-style-type: none;
	counter-reset: list;
	font-size: $smaller-font-size;
	margin-top: 2em;
	& > li {
		list-style: none;
		position: relative;
	}
	& > li:before {
		counter-increment: list;
		content: counter(list, lower-alpha) ') ';
		position: absolute;
		left: -1.4em;
	}
	&__item {
		font-weight: normal;
		display: block;
		margin-bottom: 1em;
		p {
			display: inline;
		}
		&-text {
			@include smaller-text($black);
			font-weight: normal;
		}
		.bold {
			margin-right: 0.5rem;
			font-weight: bold;
		}
	}
	.no-order {
		margin-left: -4rem;
		&:before {
			content: '';
		}
	}
}
