.container__background {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url('../assets/images/main-background.png');
	background-blend-mode: multiply;
	background-size: cover;
	-ms-overflow-style: none;
	.register-container {
		margin: 2rem 0;
		width: 90%;
		height: 80%;
		overflow-y: scroll;
		@include breakpoint(tab-port) {
			width: 80%;
		}
		@include breakpoint(tab-land) {
			width: 60%;
		}
		background-color: $white;
		opacity: 0.9;
		@include container-padding-small(3rem);
		display: flex;
		flex-direction: column;
		align-items: center;
		.box-title {
			@include header-2-text($orange);
		}
		.input-container {
			width: 100%;
		}
		.input-row .input-style {
			border-radius: 0;
			border: 0.05rem solid $light-black;
			margin: 0.5rem 0;
			&:focus {
				border-color: $orange;
			}
		}
		.agree-wrapper {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0.8rem 0;
			min-height: 4rem;
			.fa-check-circle {
				color: $green;
				margin-right: 1rem;
			}
			.agree-text {
				text-align: left;
				text-decoration: underline;
			}
		}
		.button {
			margin-top: 1.5rem;
		}
	}
	.register-button {
		background-color: $orange;
		min-width: 14rem;
	}
	.button-disabled {
		background-color: $gray;
	}
	.register-intro {
		@include normal-text($black);
		text-align: center;
		margin: 2rem 0;
		width: 100%;
		a {
			margin-left: 0.5rem;
			@include normal-text($black);
		}
	}
}
