.organisation-add {
	$self: &;
	&-header {
		@include container-padding-small(1rem);
		div.header-left {
			display: flex;
			align-items: center;
			.header-back-button {
				display: flex;
				align-items: center;
				&:hover {
					cursor: pointer;
				}
				.fa-long-arrow-alt-left {
					font-size: $normal-big-font-size;
				}
			}
			.header-text {
				@include header-2-text($white);
				margin-right: 1.5rem;
			}

			.header-separator {
				background-color: $white;
				width: 0.2rem;
				height: 3rem;
				margin-right: 1.5rem;
			}
		}
	}
	&-inner-container {
		@include container-padding-big(3rem);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		#{$self}-orange-button {
			background-color: $orange;
			&-disabled {
				background-color: $gray;
				&:hover {
					cursor: default;
					opacity: 1;
				}
			}
			&-text {
				font-size: $smaller-font-size;
				font-family: $montserrat;
				font-weight: bold;
				padding: 0.6rem 1rem;
			}
		}
		#{$self}-orange-button-container {
			max-width: 10em;
			margin-top: 3rem;
		}
	}
}
