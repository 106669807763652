.recipient-add__container {
	.recipient-add-header {
		@include container-padding-small(1rem);
		div.header-left {
			display: flex;
			align-items: center;
			.header-back-button {
				display: flex;
				align-items: center;
				&:hover {
					cursor: pointer;
				}
				.fa-long-arrow-alt-left {
					font-size: $normal-big-font-size;
				}
			}
			.header-text {
				@include header-2-text($white);
				margin-right: 1.5rem;
			}

			.header-separator {
				background-color: $white;
				width: 0.2rem;
				height: 3rem;
				margin-right: 1.5rem;
			}
		}
	}
	.recipient-add-inner-container {
		background-color: $lighter-gray;
		@include container-padding-small(3rem);
		.section {
			background-color: $white;
			padding: 3rem 3rem;
			margin-bottom: 3rem;
			@include breakpoint(tab-land) {
				padding: 2rem 5%;
			}
			@include breakpoint(desktop) {
				padding: 2rem 15%;
			}
			.recipient-list-input {
				border-color: $light-black;
				border-radius: 0;
				@include normal-text($black);
				&:focus {
					border-color: $orange;
				}

				&::placeholder {
					@include normal-text($gray);
				}
			}
			.recipient-add-title {
				@include header-2-text($black);
				margin-bottom: 1rem;
			}
			.recipient-add-subtitle {
				@include normal-text($black);
				margin-bottom: 2rem;
			}
		}
		.recipient-list-content {
			background-color: $white;
			@include box-shadow-default(0, 0.4rem, 2rem, 0, rgba(0, 0, 0, 0.05));
			&.add-multiple {
				margin-bottom: 4rem;
			}
		}
	}
}
