.list-item-normal-text {
	@include normal-big-text($black);
	text-transform: uppercase;
	font-weight: bold;
}

.list-item-right {
	display: flex;
	align-items: center;
	.list-item-number-wrapper {
		padding: 0 0.5rem;
		border-radius: 50%;
		background-color: $orange;
		margin-right: 1rem;
		width: 2rem;
		height: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.list-item-number {
		@include normal-bold-text($white);
	}
	.fa-question-square {
		margin-top: 0.3rem;
		color: $light-black;
	}
}
