.row {
	display: flex;
	align-items: center;
	.survey-title-input-container {
		.input-row .survey-title-input {
			padding-left: 0;
			background-color: transparent;
			height: 1.5em;
			@include header-2-text($black);
			letter-spacing: 0.1rem;
			border-width: 0;
			border-radius: 0;
			&:focus {
				border-bottom: 0.1rem solid $orange;
			}
			&::placeholder {
				@include header-2-text($black);
			}
		}
	}
	.save-button {
		background-color: $orange;
		margin-right: 1rem;
		&-text {
			font-size: $smaller-font-size;
			font-family: $montserrat;
			padding: 0.6rem 3rem;
		}
	}
	.cancel-button {
		background-color: $gray;
		&-text {
			font-size: $smaller-font-size;
			font-family: $montserrat;
			padding: 0.6rem 3rem;
		}
	}
}
.edit-name {
	@include normal-text($orange);
	margin-bottom: 4.5rem;
}
