.alert-save-button-container .alert-save-button {
	background-color: $orange;
	&-text {
		font-size: $normal-biggest-font-size;
	}
}
.alert-save-button-container {
	width: 12em;
}
.alert-cancel-button-container {
	width: 12em;
	margin-right: 1rem;
}
.alert-cancel-button-container .alert-cancel-button {
	&-text {
		font-size: $normal-biggest-font-size;
	}
}
