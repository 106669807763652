.organisation-selector {
	width: 100%;
	margin: 25px 0;
	padding: 15px;
	&__checkbox-wrapper {
		border-top: solid black 2px;
		height: 150px;
		display: flex;
		flex-flow: row wrap;
		overflow-y: scroll;
		&__checkbox {
			margin: 5px 8px 0 0;
			min-width: 250px;
		}
		&--input {
			max-width: 100%;
		}
		&.surveys {
			background-color: rgba(0, 0, 0, 0.041);
			height: unset;
			max-height: 400px;
			display: grid;
			grid-template-columns: 1fr 1fr;
		}
	}
	&__note {
		font-size: 1.4rem;
		font-family: 'Montserrat';
		color: #000;
		letter-spacing: 0.05rem;
		font-weight: bold;
		margin: 5px 0;
	}
	&__buttons {
		display: flex;
		justify-content: flex-start;
		margin: 5px 0;
		.button {
			display: inline-flex;
		}
		.button-filter {
			background-color: $dark-blue;
			.button-text {
				font-size: 1.1rem;
				font-family: 'Montserrat';
			}
		}
	}
}
