.control-arrow {
	position: absolute;
	top: 0;
	left: -5rem;
	background-color: $white;
	@include box-shadow-default();
	&-button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 3.9rem;
		height: 3.9rem;
		outline: none;
		&:hover {
			cursor: pointer;
		}
	}
	.fa-caret-circle-up,
	.fa-caret-circle-down {
		color: $gray;
	}
	&-separator {
		height: 0.1rem;
		width: 100%;
		background-color: $border-white;
	}
}
