.scale {
	background-color: $white;
	padding: 3rem 2rem 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	&__container {
		margin-bottom: -80px;
		&__scale-top {
			margin-top: 10rem;
			height: 20px;
			width: 400px;
			left: 50px;
			top: -4px;
			position: relative;
			transform: rotate(0deg);
			z-index: 1;
			animation: scale-top 5s 2 alternate-reverse;
			img {
				max-width: 400px;
				position: inherit;
				z-index: 4;
			}
		}
		&__triangle {
			position: relative;
			width: 500px;
			left: 0px;
			top: -105px;
			z-index: 0;
		}

		&--ball {
			position: relative;
			text-align: center;
			color: $white;
			font-weight: bold;
			font-size: 1.6rem;
			bottom: 100px;
			line-height: 50px;
			z-index: 3;

			&.red {
				background: #ff1717;
				height: 60px;
				width: 60px;
				left: 285px;
				border-radius: 100%;
				bottom: 92px;
			}
			&.green {
				background-color: #7ed957;
				height: 60px;
				width: 60px;
				left: 57px;
				bottom: 150px;
				border-radius: 100%;
			}
		}
		&__text {
			p {
				color: $light-black;
				font-weight: bold;
				font-size: 1.8rem;
			}
			li {
				color: $black;
				font-size: 1.2rem;
			}
			&.result {
				margin-bottom: 2rem;
			}
		}
	}
}
@keyframes scale-top {
	from {
		transform: rotate(var(--scale-start-degree));
	}
	to {
		transform: rotate(var(--scale-end-degree));
	}
}
@keyframes text-appear {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
