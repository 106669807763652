.year-filter {
	display: flex;
	flex-direction: column;
	width: 100%;
	h1 {
		font-size: 2rem;
		margin-bottom: 4rem;
	}
	& &__slider {
		margin: 0 1.6rem;
		width: auto;
	}
}
