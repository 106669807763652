.image-bank {
	display: flex;
	flex-direction: column;

	&__header {
		&__container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
			width: 100%;
			&__left {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 2rem;
				i {
					color: $medium-gray;
				}
				h2 {
					font-size: $normal-biggest-font-size;
					font-weight: 500;
					color: $medium-gray;
				}
				h2:last-child {
					color: $darker-gray;
				}
			}

			.header-button {
				background-color: $white;
				.icon {
					color: $medium-gray;
				}
				.button-text {
					font-size: $normal-biggest-font-size;
					color: $medium-gray;
					.button-text-inside {
						margin-left: 1rem;
					}
				}
			}
		}
	}
	&__open-add-image {
		display: flex;
		justify-content: flex-end;
		.dropdown-filter-clickable-grey-text {
			margin-right: 1rem;
			color: $gray;
		}
		text-align: left;
		margin-left: auto;
		margin-top: 4rem;
		margin-right: 2.5%;
		&__button {
			padding: 4px 10px;
			border: solid 1px $orange;
			cursor: pointer;
		}
		p {
			font-size: 1.8rem;
			font-weight: 500;
			font-family: $cabin;
			color: $medium-gray;
		}
	}

	.image-thumbnail {
		display: grid;
		gap: 2rem;
		grid-template-columns: repeat(1, minmax(350px, 1fr));
		@include breakpoint(tab-port) {
			grid-template-columns: repeat(2, minmax(250px, 1fr));
		}
		@include breakpoint(tab-land) {
			grid-template-columns: repeat(3, minmax(250px, 1fr));
		}
		@include breakpoint(desktop) {
			grid-template-columns: repeat(4, minmax(250px, 1fr));
		}
		@include breakpoint(desktop-large) {
			grid-template-columns: repeat(5, minmax(250px, 1fr));
		}
		gap: 2rem;
		height: 100%;
		width: 95%;
		margin: 0 auto;
		&__desc {
			display: flex;
			align-items: center;
			justify-content: space-between;
			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-right: auto;
				max-width: 25rem;
			}
			i {
				cursor: pointer;
			}
			&--edit-icon {
				padding: 0.5rem 1rem;
			}
			&--input-container {
				width: 100%;
				margin-right: 0.5rem;
				&.input-border {
					border: 0.1rem solid $gray;
				}
			}
		}

		&__cards {
			flex-basis: 90%;
			align-self: center;
			justify-self: center;
			max-width: 330px;
			background-size: contain;
			.MuiCardActions-root {
				margin-top: 2rem;
				padding: 0 2rem;
			}
			@include breakpoint(tab-port) {
				flex-basis: 45%;
			}
			@include breakpoint(desktop) {
				flex-basis: 25%;
			}
			@include breakpoint(desktop-large) {
				flex-basis: 20%;
			}
			.makeStyles-root-1 {
				border-radius: $small-border-radius;
				flex: 1;
				padding-bottom: 2rem;
			}
			.makeStyles-cardcontent-3 {
				border-top: 1px solid $light-gray;
				width: 100%;
				padding: 1rem 2rem;
			}
			.button-text-small {
				font-size: $small-font-size;
				font-weight: 500;
			}
			.button-orange {
				background-color: $orange;
				color: $white;

				&:hover {
					background-color: $normal-blue;
				}
			}
		}
	}

	.add-image__container {
		position: relative;
		display: flex;
		flex-direction: column;
		max-width: 40rem;
		margin-top: 4rem;
		background-color: $white;
		padding: 1rem;
		border-radius: $small-border-radius;
		.input__container {
			padding: 1rem;
		}

		p {
			padding: 0 10px;
		}

		.close-icon {
			position: absolute;
			font-size: 2.5rem;
			right: 10px;
			top: 10px;
			cursor: pointer;
		}
		&__description {
			display: flex;
			margin-top: 10px;
			i {
				font-size: 2rem;
			}
		}

		button {
			background-color: $orange;
			cursor: pointer;
		}

		.attach-image {
			display: flex;
			flex-direction: column;
			margin-bottom: 1rem;
			label {
				@include button-default($background-color: $dark-blue);

				width: 60%;
				p {
					@extend .button-text;
					padding: 0;
				}
			}
			&__file {
				display: flex;
				align-items: center;
			}
			&__error {
				color: $red;
			}
		}
	}
}
