.modal {
	.buttons-container {
		.prompt-cancel-button {
			width: 100%;
			&-container {
				width: 20em;
				margin-right: 1rem;
			}
			&-text {
				font-size: $normal-biggest-font-size;
				padding: 1rem 2rem;
			}
		}
		.prompt-save-button {
			background-color: $orange;
			&-container {
				width: 20em;
			}
			&-text {
				font-size: $normal-biggest-font-size;
				padding: 1rem 2rem;
			}
		}
	}
}
