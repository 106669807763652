// Error message base style

.error-message-container {
	width: 100%;
	display: flex;
	justify-content: center;
	position: fixed;
	bottom: 2rem;
}

.error-message-enter {
	opacity: 0.01;
	transform: translateY(500%);
}
.error-message-enter-active {
	opacity: 1;
	transform: translateY(0%);
	transition: all 400ms ease-out;
}
.error-message-exit {
	opacity: 1;
	transform: translateY(0%);
}
.error-message-exit-active {
	opacity: 0.01;
	transform: translateY(500%);
	transition: all 400ms ease-out;
}

.error-message-text {
	background: $white;
	@include normal-text($red);
	padding: 10px 25px;
	border-radius: 5px;
	border: 1px solid $gray;
}
