.analysis {
	&__items {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		padding: 2rem 3rem 0.5rem 3rem;
		position: relative;
		flex: 1;
		max-width: 100%;
		margin-bottom: 2.5rem;
	}

	&-inner-container {
		display: flex;
		flex: 1 1 24%;
		background-color: $white;

		@include breakpoint(tab-land) {
			flex: 0 1 49%;
			max-width: 49%;
			margin-right: 2%;
			&:nth-child(even) {
				margin-right: 0;
			}
		}
		@include breakpoint(desktop) {
			flex: 0 1 32.3%;
			max-width: 32.3%;
			margin-right: 1.5%;
			&:nth-child(even) {
				margin-right: 1.5%;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
		@include breakpoint(desktop-large) {
			flex: 1 1 24.25%;
			max-width: 24.25%;
			margin-right: 1%;
			&:nth-child(even) {
				margin-right: 1%;
			}
			&:nth-child(3n) {
				margin-right: 1%;
			}
			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}
}
