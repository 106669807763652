.organisation__container {
	.organisation-header {
		@include container-padding-small(2rem);
		.organisation-header-left {
			display: flex;
			align-items: center;
			.organisation-header-text {
				@include header-2-text($white);
				margin-right: 1.5rem;
			}
		}
	}
	.organisation-inner-container {
		@include container-padding-small(3rem);
	}
}
