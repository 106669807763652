.toolbar__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $white;
	padding: 1.5rem 4.5rem;
	margin-bottom: 3rem;
	.disabled {
		color: $gray;
	}
	.toolbar-left {
		display: flex;
		width: 70%;
		.toolbar-button-text {
			font-weight: bold;
		}
		.fa-plus-circle {
			color: $orange;
		}
		.fa-pen {
			color: $orange;
			margin-right: 1rem;
		}
		.toolbar-icon-wrapper {
			display: flex;
			align-items: center;
			margin-right: 1rem;
			.toolbar-icon-button {
				display: flex;
				align-items: center;
				.toolbar-input-title {
					font-weight: bold;
					&-active {
						border-radius: 0;
						height: 2em;
						border-bottom: 0.2rem solid $orange;
					}
				}
			}
			.toolbar-save-button {
				background-color: $orange;
				&-text {
					font-size: $smaller-font-size;
					font-family: $montserrat;
					padding: 0.6rem 3rem;
				}
			}
			.toolbar-save-button-container {
				margin-left: 1rem;
			}
		}
	}
	.toolbar-warning {
		@include normal-text($orange);
		text-align: center;
		margin-left: 1rem;
	}
	.toolbar-right {
		width: 30%;
		.toolbar-input {
			background-color: $darker-white;
		}
		.fa-search {
			color: $gray;
			margin-left: 0.5rem;
		}
	}
}
