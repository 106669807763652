.template-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin: 10px 0;
	&-big {
		height: 6rem;
	}
	.input__container .input-row input {
		border-color: $orange;
		border-radius: 0;
		height: 2em;
		&::placeholder {
			font-weight: normal;
		}
		&:focus {
			border-bottom: 0.1rem solid $orange;
			transition: $transition-default;
		}
	}
	.input__container .input-row input.template-row-input-disabled {
		margin-left: 1em;
		border-color: $gray;
		border-radius: 0;
		height: 2em;
		&::placeholder {
			font-weight: normal;
		}
	}
	&-label {
		display: flex;
	}
	&-text {
		@include normal-text($black);
	}
	&-checkbox {
		font-weight: bold;
	}
	&-icon-container {
		padding: 0.5rem;
		&-enabled {
			&:hover {
				cursor: pointer;
				transition: $transition-default;
			}
		}
	}
	& &-icon {
		color: $gray;
		margin-left: 1rem;
		justify-self: center;
		align-self: center;
	}
}
