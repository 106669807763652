.organisation-users {
	&-header {
		@include container-padding-small(1rem);
		&-left {
			display: flex;
			align-items: center;
		}
		&-back-button {
			display: flex;
			align-items: center;
			&:hover {
				cursor: pointer;
			}
			.fa-long-arrow-alt-left {
				font-size: $normal-big-font-size;
				color: $white;
				margin-right: 1rem;
			}
		}
		&-text {
			@include header-2-text($white);
			margin-right: 1.5rem;
		}

		&-separator {
			background-color: $white;
			width: 0.2rem;
			height: 3rem;
			margin-right: 1.5rem;
		}
	}
	&-toolbar {
		display: flex;
		align-items: center;
		&-text {
			@include header-2-text($orange);
			margin-right: 1.5rem;
		}
		.button &-button {
			background-color: $orange;
			&-text {
				@include smaller-text($white);
			}
		}
	}
	&-inner-container {
		@include container-padding-small(3rem);
	}
}
