// Typography

//Default font size of browser is 16px, so 62.5% * 16px = 10px

:root {
	font-size: 62.5%;
	--scale-start-degree: 0deg;
	--scale-end-degree: 15deg;
	--primary-color: #fc4f00;
	--secondary-color: ;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
}

* {
	box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
div,
p,
span,
input,
label,
button {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
		Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	margin: 0;
	display: block;
}

h1,
h2,
h3,
h4 {
	line-height: 1.25em;
}
p {
	line-height: 1.5em;
}

h1 {
	@include header-2-text($black);
	letter-spacing: 0.1rem;
}

h2 {
	@include header-2-text($black);
	letter-spacing: 0.1rem;
}

h3 {
	@include header-4-text($black);
	letter-spacing: 0.1rem;
}

h4 {
	@include header-5-text($black);
	letter-spacing: 0.1rem;
}

h5 {
	@include header-6-text($black);
	letter-spacing: 0.1rem;
}

p {
	@include normal-text($black);
	letter-spacing: 0.05rem;
}

span {
	letter-spacing: 0.05rem;
}

// Force our app to have default font size
// So that UI library will not override it
//
