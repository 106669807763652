.linear-scale-preview {
	margin-top: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 3rem 10%;

	&-text {
		font-size: $normal-biggest-font-size;
		margin-bottom: 2rem;
		margin-left: 0.5rem;
		width: 20%;
		&-left {
			padding-right: 3rem;
			text-align: center;
		}
		&-right {
			padding-left: 3rem;
			text-align: center;
		}
	}
}
