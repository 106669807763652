.reminder {
	padding: 2rem 2rem 2rem 0;
	&-text {
		@include header-3-text($black);
		margin-bottom: 1rem;
		text-transform: uppercase;
	}
	&-description {
		margin-bottom: 1rem;
	}
	&-footer {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 2rem;
		.button-container {
			width: 10em;
		}
		.button-disabled {
			background-color: $gray;
		}
		.button-cancel {
			background-color: $gray;
			margin-right: 1rem;
		}
		.button-send {
			background-color: $orange;
		}
		.button-text {
			@include smaller-text($white);
			padding: 0.6rem 2rem;
		}
	}
}

.dashboard-item {
	.input-container {
		margin-bottom: 2rem;
		.input-row .input {
			border-radius: 0;
			border-color: $light-black;
			@include normal-text($black);
			&::placeholder {
				@include normal-text($gray);
			}
			&:focus {
				border-color: $orange;
			}
		}
	}
	.text-area {
		width: 100%;
		.MuiInputBase-input {
			font-weight: normal;
			&::placeholder {
				font-weight: normal;
			}
		}
	}
}
