.chart {
	display: flex;
	&__legend {
		display: flex;
		align-items: center;
		margin-right: 2rem;
		&-container {
			margin-top: 2rem;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}
		&-grid-container {
			margin-top: 4rem;
		}
		&-text {
			@include small-text($light-black);
		}
		&-icon {
			margin-right: 1rem;
			width: 1.1rem;
			height: 1.1rem;
			background-color: $orange;
			&-circle {
				border-radius: 100%;
				border: 0.05rem solid $orange;
				margin-right: 0.5rem;
			}
		}
	}
	&-y-axis-tick {
		&-index {
			@include normal-text($orange);
		}
		&-text {
			@include normal-text($light-black);
		}
	}

	.recharts-text {
		@include normal-text($black);
	}
	.recharts-label {
		font-size: $small-smaller-font-size;
	}
	& &-black-text {
		fill: $light-black;
		page-break-inside: avoid;
	}
	& &-orange-text {
		fill: $orange;
		page-break-inside: avoid;
	}

	.recharts-cartesian-grid-vertical line {
		stroke: $gray;
		&:last-child {
			visibility: hidden;
		}
	}
}
