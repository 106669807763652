.meter-templates {
	position: relative;
	&-float {
		margin-top: 7rem;
	}
	&__container {
		padding: 2rem 4rem;
		width: 100%;
	}
	&__header {
		&-fixed {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 10;
		}
	}

	.header-title {
		display: flex;
		align-items: center;
		i {
			color: $medium-gray;
			margin-right: 1rem;
		}
		&-button {
			margin-right: 0.5rem;
			&:hover {
				cursor: pointer;
			}
		}
		&-text {
			font-weight: 500;
			font-size: $normal-biggest-font-size;
			color: $medium-gray;
		}
	}
	&-4-grid {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		gap: 2rem;
		margin-top: 1rem;
		margin-bottom: 3.5rem;
		.template__container {
			@include breakpoint(tab-land) {
				flex: 0 1 29.3%;
				max-width: 29.3%;
				margin-right: 1.5%;
				&:nth-child(even) {
					margin-right: 1.5%;
				}
			}
			@include breakpoint(desktop) {
				flex: 0 1 22.25%;
				max-width: 22.25%;
				margin-right: 1%;
				&:nth-child(even) {
					margin-right: 1%;
				}
			}
			@include breakpoint(desktop-large) {
				flex: 0 1 15.83%;
				max-width: 15.83%;
				margin-right: 1%;
				&:nth-child(even) {
					margin-right: 1%;
				}
			}
			.template-category-chips {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				margin-top: 1rem;
				justify-content: space-around;
				.MuiChip-root {
					margin: 0.5rem;
				}
			}
		}
	}
	&-6-grid {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: 3rem;
		margin-bottom: 5rem;
	}
	&-info {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		margin-top: 2em;
		.icon {
			color: $orange;
			margin-right: 1em;
		}
		.text {
			@include normal-text($black);
		}
	}
}
