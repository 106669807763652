.footer__container {
	@include nav-padding(2rem);
	background-color: $dark-blue;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.footer-left {
		display: flex;
		&-text {
			text-align: center;
			@include normal-text($white);
		}
	}
	.footer-right {
		display: flex;
		.footer-item {
			margin: 0 1rem;
			@include breakpoint(desktop) {
				margin: 0 2rem;
			}
			@include breakpoint(desktop-large) {
				margin: 0 5rem;
			}
			&:hover {
				cursor: pointer;
				transition: $transition-default;
				opacity: 0.7;
			}
			&-text {
				text-align: center;
				@include normal-text($white);
			}
		}
	}
}
