.loading-wrapper {
	width: 100%;
	height: 100%;
	z-index: 10000000000000000;
	.loading-modal-container {
		position: absolute;
		top: 30%;
		left: 43%;
	}
	.loading-modal-content {
		background-color: transparent;
	}
	.loading {
		margin: 12px auto;
		width: 64px;
		height: 64px;
	}
	.loading:after {
		content: ' ';
		display: block;
		width: 46px;
		height: 46px;
		margin: 1px;
		border-radius: 50%;
		border: 5px solid $orange;
		border-color: $orange transparent $orange transparent;
		animation: loading 1.2s linear infinite;
	}
	@keyframes loading {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}
