.nav-bar {
	position: absolute;
	top: 7.5rem;
	right: 1rem;
	z-index: 10000;
	visibility: hidden;
	@include breakpoint('tab-land') {
		visibility: visible;
		position: static;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	//&-focus {
	//	z-index: 1000000001; //above modal
	//}

	&-open {
		visibility: visible;
		border-radius: 0.4rem;
		@include box-shadow-default(0px, 4px, 20px, 0, $gray);
		animation: {
			name: dropdown;
			duration: 200ms;
			timing-function: ease-in-out;
			fill-mode: forwards;
		}
		@include breakpoint('tab-land') {
			@include box-shadow-default(0, 0, 0, 0, transparent);
		}
	}
}
