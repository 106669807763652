.image-row {
	&__options {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;

		i {
			margin: 0 0.5rem;
			font-size: 1.2rem;
			cursor: pointer;
		}
	}
}
