.survey-info {
	display: flex;
	flex-direction: column;
	margin-bottom: 1.5rem;
	&-select {
		width: 100%;
		margin-bottom: 3rem;
		.MuiInputBase-root {
			width: 100%;
			.MuiSelect-root {
				height: 2em;
				display: flex;
				align-items: center;
			}
		}
	}
	&-title {
		@include header-2-text($light-black);
		margin-bottom: 1.5rem;
	}
	&-label {
		&-text {
			@include normal-text();
			margin-bottom: 2.5rem;
		}
	}
	&-input {
		font-weight: bold;
	}
	&-save-button {
		margin-left: auto;
		max-width: 20rem;
		&.meter {
			margin-right: auto;
			margin-left: 0;
		}
	}
	& &-input-container {
		margin-bottom: 5rem;
		.input-row input {
			border-color: $gray;
			font-weight: bold;
			&:focus {
				border-color: $orange;
			}
		}
	}
	.survey-info-textarea {
		width: 100%;
		margin-bottom: 2.5rem;
		&::before {
			border-width: 0;
		}
	}
	&__buttons {
		display: flex;
		flex-direction: row;
		&.item-switch {
			justify-content: space-between;
		}
	}
}
