.chart-result-wrapper {
	background-color: $white;
	padding: 2rem 3rem;
	margin-bottom: 1.6rem;
	@media print {
		padding: 2rem 1rem 1rem 1rem;
		margin-bottom: 0.8rem;
	}
	.title-text {
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		border-bottom: 0.2rem solid $orange;
	}
	i {
		margin-left: 0.5rem;
		cursor: pointer;
	}
}
