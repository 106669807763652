.login {
	width: 100%;
	height: calc(100vh - 12.8rem);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url('../assets/images/main-background.png');
	background-blend-mode: multiply;
	background-size: cover;
	&-container {
		width: 80%;
		@include breakpoint(tab-port) {
			width: 50%;
		}
		@include breakpoint(desktop) {
			width: 30%;
		}
		@include breakpoint(desktop-large) {
			width: 20%;
		}
		background-color: $white;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 0.4rem;
		overflow: hidden;
		opacity: 0.9;
		.box-logo-container {
			display: flex;
			height: 100%;
			width: 100%;
			padding: 4rem 0;
			background-color: $light-gray;
			justify-content: center;
			align-items: center;
			.box-logo {
				width: 50%;
				height: auto;
				max-width: 7em;
				max-height: 2.5em;
			}
		}
		.form-container {
			display: flex;
			height: 100%;
			width: 100%;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			margin: 2em 1em 0 1em;
			.forgot-password {
				margin: 1rem 0;
				&:hover {
					cursor: pointer;
				}
				&-text {
					@include small-text($black);
				}
			}
			.form-input {
				&-container {
					width: 80%;
				}
			}
		}
		.sign-in-button-container {
			background-color: $orange;
			border-radius: 0;
			width: 100%;
			.sign-in-button {
				background-color: $orange;
				border-radius: 0;
				width: 100%;
			}
		}
	}
	.create-account-hint {
		&-text {
			@include small-text($black);
		}
		margin-top: 2em;
		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}
	}
}
