.section-filter {
	padding: 2rem 3rem;
	background-color: $white;
	width: 100%;
	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		h1 {
			@include header-2-text($black);
		}
		.switch-container {
			margin-right: 0;
		}
	}
	&__description {
		margin: 1.4rem 0 2rem 0;
	}
	&__checkbox-wrapper {
		margin-top: 1rem;
	}
	.footer {
		margin-top: 1rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex-direction: row;
		.flex {
			flex: 1;
		}
		.button-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			.checkbox {
				margin-right: 2rem;
			}

			.cancel-button {
				background-color: $gray;
			}
			.confirm-button {
				background-color: $orange;
			}
			.button-text {
				font-family: $montserrat;
				font-size: $smaller-font-size;
			}
			.button-container {
				width: 12em;
				&-left {
					margin-right: 1rem;
				}
			}
			.disabled-button {
				background-color: $gray;
				&:hover {
					cursor: not-allowed;
					opacity: 1;
				}
			}
		}
		+ .open-advance-filter-button-container {
			margin-top: -2.5rem;
		}
	}
	&__open-advance-button-container {
		margin-top: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.button-text {
			font-family: $montserrat;
			font-size: $smaller-font-size;
		}
		.cancel-button {
			background-color: $gray;
		}
		.button-container {
			width: 12em;
			&-left {
				margin-right: 1rem;
			}
		}
		.tooltip {
			margin-left: 1rem;
			color: $orange;
		}
	}
	&__open-advance-button {
		display: flex;
		align-items: center;
	}
	&__searchbox {
		display: flex;
		width: 100%;
		max-width: 20rem;
		.input-row input {
			background: url(../assets/images/search.png) no-repeat scroll 17rem 1.2rem;
			background-color: $white;
			border-radius: 0.5rem;
			padding-right: 4rem;
		}
	}
}

.open-advance-filter-button-container {
	justify-content: flex-start;
	.open-advance-filter-button {
		width: auto;
	}
}
