.organisation-edit {
	$self: &;
	.MuiAutocomplete-popper {
		z-index: 100;
	}
	&-header {
		@include container-padding-small(1rem);
		div.header-left {
			display: flex;
			align-items: center;
			.header-back-button {
				display: flex;
				align-items: center;
				&:hover {
					cursor: pointer;
				}
				.fa-long-arrow-alt-left {
					font-size: $normal-big-font-size;
				}
			}
			.header-text {
				@include header-2-text($white);
				margin-right: 1.5rem;
			}

			.header-separator {
				background-color: $white;
				width: 0.2rem;
				height: 3rem;
				margin-right: 1.5rem;
			}
		}
	}
	&-inner-container {
		@include container-padding(3rem);
		display: flex;
		flex-direction: row;
		justify-content: center;
		#{$self}-orange-button {
			background-color: $orange;
			//margin-right: 1.5rem;
			&-disabled {
				background-color: $gray;
				&:hover {
					cursor: default;
					opacity: 1;
				}
			}
			&-text {
				font-size: $smaller-font-size;
				font-family: $montserrat;
				font-weight: bold;
				padding: 0.6rem 2rem;
			}
		}
		#{$self}-gray-button {
			background-color: $gray;
			&:hover {
				cursor: default;
				opacity: 1;
			}
			&-text {
				font-size: $smaller-font-size;
				font-family: $montserrat;
				font-weight: bold;
				padding: 0.6rem 2rem;
			}
		}

		#{$self}-orange-button-container {
			max-width: 10em;
			margin-right: 1.5rem;
		}
	}
	@include breakpoint(tab-port) {
		flex-direction: row;
	}
	&-title {
		@include header-2-text($black);
		margin-bottom: 1rem;
	}
	&-options {
		flex: 1;
	}
	&-parent-org {
		h1 {
			margin-bottom: 1rem;
		}
		h2 {
			margin: 2rem 0;
		}
		.checkbox {
			margin-bottom: 1rem;
		}
		background-color: white;
		border-top: 0.2rem solid $orange;
		padding: 2rem;
		margin: 0 0 1.6rem 1.6rem;
		.MuiAutocomplete-tag {
			border: 0.05rem solid $light-black;
			background-color: $white;
		}
		.MuiChip-label {
			font-family: $montserrat;
		}
		.MuiChip-deleteIcon {
			color: $orange;
		}
		.MuiAutocomplete-popper {
			z-index: 1;
		}
		.MuiOutlinedInput-root {
			border-radius: 0;
		}
		.button button {
			margin-top: 1rem;
			width: inherit;
		}
		.MuiFormLabel-root {
			font-size: 1.4rem;
			color: $black;
			font-family: $montserrat;
		}
	}
	&-section {
		display: flex;
		flex-direction: column;
		background-color: white;
		border-top: 0.2rem solid $orange;
		padding: 2rem 2rem 4rem 2rem;

		&-left {
			margin-left: 1.6rem;
		}
		&-button-wrapper {
			margin-top: 2.5rem;
			display: flex;
			align-items: center;
		}
	}
}
