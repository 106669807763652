.number-input {
	padding: $template-padding;
	margin-bottom: 3rem;
	& .input__container .input-row &-input {
		border-bottom: 0.1rem solid $gray;
		border-radius: 0;
		&:focus {
			border-color: $orange;
		}
	}
}
