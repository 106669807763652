.recipient-summary__container {
	.recipient-summary-header {
		&__container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
			width: 100%;
			h1 {
				font-size: $normal-biggest-font-size;
				font-weight: 500;
				color: $medium-gray;
			}
			.header-button {
				background-color: white;
				&-text {
					font-size: $normal-biggest-font-size;
					color: $medium-gray;
					.button-text-inside {
						margin-left: 1rem;
					}
				}
			}
		}
	}
	.recipient-summary-inner-container {
		padding: 3rem 2.5%;
		display: flex;
		width: 100%;
		flex-wrap: wrap;
	}
}
