.add-image__container {
	// position: relative;
	// display: flex;
	// flex-direction: column;
	// align-items: flex-start;
	// .attach-image {
	// 	&__button {
	// 		border: 0.1rem solid $orange;
	// 		cursor: pointer;
	// 		margin: 0.5rem 0;
	// 		padding: 0 0.5rem;
	// 		&:hover {
	// 			border: 0.1rem solid $dark-blue;
	// 		}
	// 	}
	// 	&__error {
	// 		color: $red;
	// 	}
	// }

	// .button {
	// 	button {
	// 		height: 3rem;
	// 		width: 10rem;
	// 		.button-text {
	// 			font-size: 1.4rem;
	// 		}
	// 	}
	// }
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 40rem;
	margin: 0.5rem 2rem;
	background-color: $white;
	padding: 1rem;
	flex: 1;
	.input__container {
		padding: 1rem;
	}

	&--input-container {
		width: 100%;
		margin-right: 0.5rem;
		&.input-border {
			border: 0.1rem solid $gray !important;
		}
		&.input-border:focus {
			border: unset;
		}
	}

	p {
		padding: 0 10px;
	}

	.close-icon {
		position: absolute;
		font-size: 2.5rem;
		right: 10px;
		top: 10px;
		cursor: pointer;
	}
	&__description {
		display: flex;
		margin-top: 10px;
		i {
			font-size: 2rem;
		}
	}

	button {
		width: 15rem;
		background-color: $orange;
		cursor: pointer;
	}

	.attach-image {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		label {
			@include button-default($background-color: $dark-blue);

			width: 60%;
			p {
				@extend .button-text;
				padding: 0;
			}
		}
		&__file {
			display: flex;
			align-items: center;
		}
		&__error {
			color: $red;
		}
	}
}
