.delete-survey {
	.button-text {
		font-family: $montserrat;
		font-size: $small-font-size;
	}
	.button-cancel {
		background-color: $gray;
		width: 10em;
		margin-right: 1em;
	}
	.button-confirm {
		background-color: $orange;
		width: 10em;
	}
}
