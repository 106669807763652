.toolbar__button {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	padding: 0 1rem;
	&-text {
		@include normal-text();
		text-align: left;
		word-break: break-word;
		hyphens: auto;
	}

	&:hover {
		cursor: pointer;
		transition: $transition-default;
	}
	.icon {
		margin-right: 1rem;
		color: $gray;
		&.disabled {
			color: $gray;
		}
	}
	&-disabled {
		color: $gray;
		&:hover {
			cursor: not-allowed;
		}
	}
}

.icon-button {
	align-items: center;
	color: $black;
	display: flex;
	font-family: $montserrat;
	font-size: $normal-font-size;
	font-weight: 600;
	padding: 0;

	.icon__plus-circle {
		background-image: url('../assets/icons/circle-plus.svg');
		background-position: center;
		border-radius: 50%;
		display: block;
		height: 2.5rem;
		width: 2.5rem;
	}
}
