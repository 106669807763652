.page-break {
	display: flex;
	align-items: center;
	padding: 1.6rem 3rem;
	background-color: $white;
	border-left: 0.3rem solid $orange;
	margin-bottom: 2.5rem;
	&-left {
		@include normal-text();
		display: flex;
		align-items: center;
		.fa-sticky-note {
			margin-right: 1rem;
			color: $gray;
		}
		.fa-trash {
			margin-right: 1rem;
			color: $gray;
		}
	}
	&-center {
		@include normal-text();
		flex: 1;
		text-align: center;
	}
	& &-right {
		justify-content: flex-end;
	}
	&__button-disabled {
		visibility: hidden;
	}
}
