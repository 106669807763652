.dropdown-template__container {
	padding: $template-padding;
	.dropdown-row {
		display: flex;
		padding: 1rem 0;
		align-items: center;
		justify-content: space-between;
		&-extra {
			outline: none;
			height: 4.8rem;
		}
		&-extra-text {
			@include normal-text($gray);
		}
		&-content {
			display: flex;
			align-items: center;
			flex: 1;
		}
		.dropdown-order {
			@include normal-bold-text($orange);
			margin-right: 1rem;
		}
		.input-row input {
			@include normal-text($light-black);
			border-radius: 0;
			height: 2em;
			width: 100%;
			&:focus {
				border-bottom: 0.1rem solid $orange;
				transition: $transition-default;
			}
		}
		&-icon-container {
			padding: 0.5rem;
			&-enabled {
				&:hover {
					cursor: pointer;
					transition: $transition-default;
				}
			}
		}
		&-icon {
			color: $gray;
		}
	}
}
