.result-summary {
	&-header-container {
		height: 6rem;
		display: flex;
		justify-content: flex-start;
		padding-left: 7rem;
	}
	background-color: $darker-white;
	&-inner-container {
		@include container-padding-extra-smallest(0rem);
		@include breakpoint('phone') {
			padding: 2.5rem 1rem;
		}
	}
	&-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include breakpoint('phone') {
			flex-direction: column;
		}
		&-title {
			@include small-text($black);
			font-weight: bold;
			text-transform: uppercase;
		}
	}
	&-filters {
		display: flex;
		align-items: center;
		@include breakpoint('phone') {
			flex-wrap: wrap;
		}
	}
	&-content {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin-top: 3.5rem;
		margin-bottom: 10rem;
		position: relative;
	}
	.alert-button {
		margin-right: 1rem;
		&-text {
			font-size: $normal-font-size;
			padding: 0.9rem 4rem;
		}
	}
	.alert-orange-button {
		background-color: $orange;
	}
}
