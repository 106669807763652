.edit-admin-modal {
	padding: 2rem 0rem;
	margin: 5rem 0;
	position: relative;

	&__container {
		margin: 0;
	}
	&__header {
		padding: 0 3rem;
	}
	&__body {
		max-height: 70vh;
		overflow-y: scroll;
		padding: 0 3rem;
	}
	&__separator {
		height: 0.1rem;
		width: 90%;
		background-color: $darker-white;
		margin: 0.5rem auto 1rem;
	}
	.close-icon {
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem;
	}
	.button-wrapper {
		margin: 2rem auto 1rem;
		display: flex;
		justify-content: center;
		align-items: center;

		.button:not(:last-child) {
			margin-right: 1rem;
		}
	}
}
