.collapsible {
	width: 100%;
	&__title {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		outline: none;
		&-disabled {
			opacity: 0.4;
		}
		.icon-wrapper {
			&:hover {
				cursor: pointer;
			}
			&-disabled {
				opacity: 0.4;
				&:hover {
					cursor: not-allowed;
				}
			}
		}
		&-text {
			@include normal-text($black);
			text-align: left;
			margin-right: 1rem;
			&_disabled {
				color: $light-black;
				opacity: 0.4;
			}
		}
		&-big-text {
			text-align: left;
			@include header-2-text($black);
		}
		&-left {
			display: flex;
			align-items: center;
		}
		.fa-caret-up .fa-caret-down {
			color: $black;
		}
		.fa-question-circle {
			color: $orange;
			margin: 0 1.5rem;
		}
	}
	&__content {
		&-list {
			margin-top: 1rem;
			margin-left: 1.2rem;
		}
		&-collapsible-list {
			margin-top: 1rem;
		}
		&-collapsible-container {
			padding: 1rem 0.5rem;
			border-bottom: 0.05rem solid $border-white;
		}
		&-nested {
			padding-left: 0.8rem;
		}
		&-enter {
			opacity: 0.01;
			&-active {
				opacity: 1;
				transition: all 200ms ease-in-out;
			}
		}
		&-active {
			opacity: 1;
		}
		&-exit {
			opacity: 1;
			&-active {
				opacity: 0.01;
				transition: all 200ms ease-in-out;
			}
		}
	}
}
