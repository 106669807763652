.recipient-list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 4.5rem;
	min-height: 4.4rem;
	width: 100%;
	position: relative;
	&:hover {
		cursor: pointer;
	}
	&-white {
		background-color: $white;
	}
	&-gray {
		background-color: $darker-white;
	}
	div.recipient-list-item-left {
		display: flex;
		justify-content: space-between;
		width: 70%;
		position: relative;

		.recipient-list-item-input {
			background-color: transparent;
			&:disabled {
				background-color: transparent;
			}
			@include normal-text($black);
			border-radius: 0;
			height: 2em;
			&-active {
				border-bottom: 0.2rem solid $orange;
			}
			&-container {
				flex: 1;
				margin-right: 2rem;
				&.email {
					flex: 2;
				}
			}
			&-bold {
				font-weight: bold;
			}
		}
	}
	&-warning {
		@include normal-text($orange);
		margin-left: 1rem;
	}
	&-right {
		display: flex;
		flex: 1;
		&-focus {
			justify-content: space-between;
		}
		&-unfocus {
			justify-content: flex-end;
		}
		.recipient-list-item-container {
			&:hover {
				cursor: pointer;
				> i {
					font-weight: 600;
				}
			}

			.fa-pen {
				color: $orange;
				margin-right: 2rem;
				font-size: 2em;
			}
			.fa-user-slash,
			.fa-user-check {
				color: $gray;
				font-size: 1.8em;
			}
		}
		.recipient-list-item-save-button {
			background-color: $orange;
			&-text {
				font-size: $smaller-font-size;
				font-family: $montserrat;
				padding: 0.6rem 2rem;
			}
		}
		.recipient-list-item-cancel-button {
			background-color: $gray;
			&-text {
				font-size: $smaller-font-size;
				font-family: $montserrat;
				padding: 0.6rem 2rem;
			}
		}
	}

	&.deactivated-user {
		.recipient-list-item-left .recipient-list-item-input {
			text-decoration: line-through;
			text-decoration-color: $dark-gray;
		}
	}
	&-checkbox-container {
		display: flex;
		flex-direction: column;
	}
}
