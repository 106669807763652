.show-notification {
	width: 100%;
	height: 100%;
	.header-text {
		color: $white;
	}
	&__toolbar {
		padding: 1.2rem 3rem;
		background-color: $white;
		width: 100%;
		margin-bottom: 3rem;
		@include box-shadow-default();
	}
	&__container {
		@include container-margin(3rem);
		.notification-list {
			@include box-shadow-default();
		}
	}
	.button-text {
		@include smaller-text($white);
	}
	.button-confirm {
		background-color: $orange;
	}
	.button-left {
		margin-right: 1.2rem;
	}
	.button-container {
		width: 12rem;
	}
}
