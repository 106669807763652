$question-width: 20%;

.multiple-choice-grid {
	padding: $template-padding;
	&-label {
		display: flex;
		margin-bottom: 1rem;
		.label-empty {
			width: $question-width;
		}
		.label-options {
			flex: 1;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.label-text {
			white-space: pre-line;
			text-align: center;
		}
		.label-container {
			flex: 1;
			display: flex;
			align-items: flex-end;
			justify-content: center;
			position: relative;
			margin: 0 0.5rem 1rem 0.5rem;
			height: auto;
			.label-icon-container {
				position: absolute;
				top: -1.5rem;
				right: 50%;
				margin-right: -0.9em;

				&:hover {
					cursor: pointer;
				}
				.label-icon {
					color: $light-black;
				}
			}

			.label-input {
				@include normal-text($light-black);
				border-radius: 0;
				margin-top: 1rem;
				height: 2em;
				.MuiInputBase-input {
					text-align: center;
					padding: 0;
					border: 0;
					font-weight: normal;
					word-break: break-word;
					hyphens: auto;
					&:focus {
						border-bottom: 0.1rem solid $orange;
					}
				}
			}
		}
		.switch__container {
			display: flex;
			flex: 1;
			flex-direction: column;
			align-items: center;
			justify-content: flex-end;
			height: 100%;
			.switch-text {
				@include normal-text($gray);
				flex-wrap: wrap;
				text-align: center;
				margin-bottom: 1rem;
				position: relative;
				word-break: break-word;
				hyphens: auto;
			}
		}
	}
	.icon-big {
		width: 10em;
	}
	.icon-small {
		width: 2em;
	}
	&__arrow-container {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		width: 40%;
		@include breakpoint(phone) {
			width: 100%;
			margin-bottom: 0.5rem;
		}
	}
	&__arrow {
		width: 50px;
		height: 70px;
		background: #ffe4a2;
		position: relative;
		margin-right: 10%;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 0;
			height: 0;
			border-left: 35px solid $white;
			border-top: 35px solid transparent;
			border-bottom: 35px solid transparent;
		}

		&:before {
			content: '';
			position: absolute;
			right: -30px;
			bottom: 0;
			width: 0;
			height: 0;
			border-left: 30px solid #ffe4a2;
			border-top: 35px solid transparent;
			border-bottom: 35px solid transparent;
		}
	}
	&__arrow-down {
		width: 60px;
		max-width: 60px;
		min-width: 60px;
		height: 60px;

		background: #ffe4a2;
		position: relative;

		@include breakpoint(phone) {
			width: 60px;
			max-width: 60px;
			min-width: 60px;
			margin: 0 1%;
		}
		&:before {
			content: '';
			position: absolute;
			left: 0px;
			right: 0px;
			bottom: -14px;
			width: 0;
			height: 0;
			border-left: 30.5px solid transparent;
			border-right: 30.5px solid transparent;
			border-bottom: 15px solid #ffe4a2;
			-webkit-transform: rotate(180deg);
			-ms-transform: rotate(180deg);
			transform: rotate(180deg);
		}
	}
	&__arrow-text {
		height: 5rem;
		width: 5rem;
		color: white;
		border-radius: 100%;
		margin-top: 10%;
		text-align: center;
		line-height: 50px;
		font-weight: bold;
	}
	.multiple-choice-row {
		display: flex;
		padding: 1rem 0;
		&-target {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				@include normal-text($gray);
			}
			&-input-container {
				flex: 1;
				.multiple-choice-row-target-input {
					text-align: center;
					border-width: 0;
					margin: 0 1rem;
					height: 2em;
				}
			}
		}

		&-text {
			white-space: pre-line;
			width: $question-width;
		}

		&-options {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&-flex {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&.arrow {
			max-width: 60px;
		}
		.icon-wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;
		}
		&-icon {
			display: flex;
			justify-content: flex-end;
			color: $gray;
			&-enabled {
				&:hover {
					cursor: pointer;
				}
			}
		}
		&-add-icon {
			display: flex;
			justify-content: flex-end;
			&-disabled {
				.fa-plus-circle {
					color: $gray;
				}
			}
			&:hover {
				cursor: pointer;
			}
			.fa-plus-circle {
				color: $orange;
			}
		}
		.multiple-choice-input {
			width: $question-width;
			display: flex;
			align-items: center;
			justify-content: center;
			.MuiInputBase-input {
				text-align: left;
				height: 2em;
				padding: 0 0 0.5rem;
				border: 0;
				font-weight: normal;
				&:focus {
					border-bottom: 0.1rem solid $orange;
				}
			}
		}

		.input-row input {
			@include normal-text($light-black);
			border-radius: 0;
			&:focus {
				border-bottom: 0.1rem solid $orange;
			}
		}

		.multiple-choice-row-input {
			width: $question-width;
			display: flex;
			justify-content: center;
			.MuiInputBase-input {
				height: 2em;
				padding: 0 0 0.5rem;
				text-align: left;
				border: 0;
				font-weight: normal;
				&:focus {
					border-bottom: 0.1rem solid $orange;
				}
			}
		}
		.multiple-choice-row-button-extra {
			outline: none;
			width: $question-width;
			display: flex;
			align-items: center;
			span {
				@include normal-bold-text($gray);
				opacity: 0.4;
			}
		}
	}
}
