.input__container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0.5em 0;
	.input-row {
		display: flex;
		flex-direction: row;
		.icon-container {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 3em;
			background-color: $light-gray;
			border-top-left-radius: 0.3em;
			border-bottom-left-radius: 0.3em;
		}
		input {
			@include normal-text($light-black);
			font-weight: normal !important;
			height: 3em;
			width: 100%;
			border-top-right-radius: 0.3em;
			border-bottom-right-radius: 0.3em;
			outline: none;
			&:focus {
				list-style-type: none;
				border-color: $orange;
				transition: $transition-default;
			}
			&::placeholder {
				@include normal-text($gray);
			}
			&:disabled {
				background-color: $white;
			}
		}

		.input-border {
			padding: 1em;
			border: 0.1rem solid $light-gray;
		}
		.input-no-border {
			border-width: 0;
			padding: 0;
		}
	}
	.input-error {
		@extend %error_message;
		margin-top: 1rem;
	}
}
