.profile__container {
	height: 100%;
	background-color: $dark-white;
	.profile-header {
		@include container-padding(2rem);
		.header-left-text {
			font-size: $bigger-font-size;
			font-family: $cabin;
			color: white;
			text-transform: uppercase;
		}
	}
	.profile-inner-container {
		@include container-padding(3rem);

		.profile-picture {
			background-color: white;
			border-top: 0.2rem solid $orange;
			padding: 2rem 2rem 6rem 2rem;
			margin-bottom: 3rem;
			&-title {
				@include header-2-text($black);
				margin-bottom: 2rem;
			}
			&-content {
				display: flex;
				align-items: center;
			}
			&-image {
				width: 9rem;
				height: 9rem;
				border-radius: 100%;
				margin-right: 3rem;
			}
			&-description {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 9rem;
			}
			&-download {
				@include header-2-text($light-black);
			}
			&-row {
				display: flex;
				align-items: center;
				&-text {
					@include normal-text($light-black);
					margin-right: 2rem;
				}
			}
			&-delete {
				&:hover {
					cursor: pointer;
				}
				&-text {
					@include normal-text($orange);
				}
			}
			&-download-button-container {
				max-width: 10rem;
			}
			&-download-button {
				background-color: $orange;
				&-text {
					font-size: $smaller-font-size;
					font-family: $montserrat;
					padding: 0.6rem 2rem;
				}
			}
		}
		.profile-info {
			display: flex;
			flex-direction: column;
			@include breakpoint(tab-port) {
				flex-direction: row;
			}
			&-title {
				@include header-2-text($black);
				margin-bottom: 1rem;
			}
			&-section {
				flex: 1;
				display: flex;
				flex-direction: column;
				background-color: white;
				border-top: 0.2rem solid $orange;
				padding: 2rem 2rem 4rem 2rem;
				&-left {
					margin-left: 1.6rem;
				}
				&-button-wrapper {
					display: flex;
					justify-content: space-between;
				}
				&-button-container {
					max-width: 12rem;
					margin-top: 3rem;
				}
				&-orange-button {
					background-color: $orange;
					&-text {
						@include smaller-text($white);
						text-transform: uppercase;
						padding: 0.6rem 3rem;
					}
				}
				&-gray-button {
					background-color: $gray;

					&-text {
						@include smaller-text($white);
						text-transform: uppercase;
						padding: 0.6rem 3rem;
					}
				}
				&-disabled-button {
					background-color: $gray;
					&:hover {
						cursor: default;
						opacity: 1;
					}
				}
			}
		}
	}
}
