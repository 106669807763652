.form-preview__container {
	display: flex;
	flex-direction: column;
	.form-preview-header {
		padding: 1.5rem 3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		div &-left {
			flex: 1;
			&-button-container {
				max-width: 16rem;
			}
			&-button {
				border: 0.2rem solid $white;
			}
			&-button-text {
				font-family: $cabin;
				color: $white;
				font-size: $normal-biggest-font-size;
			}
			.fa-long-arrow-left {
				color: $white;
				margin-right: 0.7rem;
			}
		}
		&-center {
			display: flex;
			justify-content: center;
			&-text {
				@include header-2-text($white);
			}
		}
		&-right {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			&-icon {
				margin-right: -1rem;
				padding: 1rem;
				&:hover {
					cursor: pointer;
				}
				.fa-times {
					color: $white;
				}
			}
		}
	}
	.form-preview-inner-container {
		background-color: $darker-white;
		@include container-padding(3rem);

		.clear-answer {
			display: flex;
			justify-content: flex-end;
			cursor: pointer;
			color: $dark-gray;
			font-size: $small-font-size;
			&.start {
				justify-content: flex-start;
			}
		}
		*:focus {
			border: 2px solid $dark-blue !important;
		}
		.form-preview-title {
			background-color: $white;
			width: 100%;
			padding: 2.3rem 3rem;
			margin-bottom: 3rem;
			display: flex;
			align-items: center;
			&-text {
				@include header-2-text($light-black);
				margin-right: auto;
			}
		}
		.form-preview-progress-container {
			margin: 2.5rem 0;
		}
		.form-preview-section {
			border-left-width: 0;
			&-title {
				@include header-2-text($light-black);
				white-space: pre-line;
			}
		}
		.form-preview-reset-text {
			text-align: center;
			margin: 20px;
		}
	}
	.form-preview-footer {
		display: flex;
		justify-content: center;
		&-left-button-container {
			width: 16rem;
		}
		&-left-button {
			border: 0.2rem solid $white;
			background-color: $dark-gray;
		}
		&-left-button-text {
			font-family: $cabin;
			color: $white;
			font-size: $normal-biggest-font-size;
		}
		&-right-button-container {
			width: 16rem;
			margin-left: 2rem;
		}
		&-right-button {
			border: 0.2rem solid $white;
			background-color: $orange;
		}
		&-right-button-disabled {
			border: 0.2rem solid $white;
			background-color: $gray;
			&:hover {
				cursor: default;
				opacity: 1;
			}
		}
		&-right-button-text {
			font-family: $cabin;
			color: $white;
			font-size: $normal-biggest-font-size;
		}
		.fa-long-arrow-left {
			color: $white;
			margin-right: 0.7rem;
		}
		.fa-long-arrow-right {
			color: $white;
			margin-left: 0.7rem;
		}
	}

	.form-preview__image {
		display: flex;
		flex: 1;
		&--view {
			max-width: 50vh;
			img {
				max-width: 100%;
			}
		}
	}
	.form-preview__own-answers {
		margin: 0;
		display: flex;
		flex-direction: column;
		padding-bottom: 1rem;
		justify-content: center;
		align-items: flex-start;
		&__checkbox {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
		&__email-input {
			width: 70%;
			max-width: 25rem;
			border-color: $dark-blue !important;
		}
		&__send-button {
			max-width: 20rem;
		}
	}
	.form-preview__survey-image {
		display: flex;
		flex: 1;
		img {
			max-width: 100%;
			max-height: 20rem;
			margin: 0 auto;
		}
	}
}
