.result-detail {
	&__header {
		height: 5rem;
	}
	&__container {
		@include container-padding-smallest(5rem);
		background-color: $darker-white;
		margin-top: -7rem;
		display: flex;
		padding-top: 0rem;
		&-left {
			flex: 1;
			height: 100%;
			margin-right: 1.6rem;
			> * {
				margin-bottom: 2rem;
			}

			.button-wrapper {
				display: flex;
				flex-direction: column;
				@include breakpoint(desktop) {
					flex-direction: row;
				}
				justify-content: center;
				padding: 3rem 0;
				.button-clear {
					background-color: $gray;
					margin-bottom: 1rem;
					margin-right: 0;
					@include breakpoint(desktop) {
						margin-right: 1rem;
						margin-bottom: 0;
					}
					width: 12rem;
					&-text {
						font-size: $smaller-font-size;
						font-family: $montserrat;
					}
				}
				.button-compare {
					background-color: $orange;
					width: 12rem;
					&-text {
						font-size: $smaller-font-size;
						font-family: $montserrat;
					}
				}
			}

			.open-advance-comparison-icon {
				background-color: $white;
				padding: 0.6rem 1rem;
				border-radius: 2rem;
			}
		}
		&-right {
			flex: 3;
			&-section {
				padding: 1.5rem 3rem;
				margin-bottom: 1rem;
				display: flex;
				justify-content: space-between;
				background-color: $white;
				.button-wrapper {
					display: flex;
					align-items: center;
				}
				.button-orange {
					background-color: $orange;
					&-container {
						width: 18rem;
					}
					&-small-container {
						width: 12rem;
					}
					&-text {
						font-size: $smaller-font-size;
						font-family: $montserrat;
						padding: 0.6rem 1rem;
						font-weight: bold;
					}
				}
			}
		}
		.print-drop-down {
			top: 3rem;
			&-container {
				z-index: 2;
			}
			.dropdown-text {
				font-size: $normal-font-size;
				// text-transform: uppercase;
			}
			.dropdown-item {
				min-width: 20em;
			}
			.button {
			}
		}
	}
	&__comparison-wrapper {
		background-color: $white;
		border-top: 0.1rem solid $orange;
	}

	&__advance-filter-preview {
		background-color: $white-gray;
		padding: 2rem;
		margin-bottom: 1rem;
		&-title {
			color: $white;
			font-size: $small-font-size;
			font-family: $montserrat;
			margin-bottom: 2rem;
		}
		&__group {
			&:not(:last-child) {
				margin-bottom: 1rem;
			}
			&-title {
				font-size: $small-font-size;
				font-family: $montserrat;
				background-color: $white;
				color: $gray;
				width: fit-content;
				padding: 0.6rem 1rem;
				border-top-left-radius: 1rem;
				border-top-right-radius: 1rem;
				margin-left: 1rem;
			}
			&-item {
				background-color: $darker-white;
				padding: 1rem 2rem;
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				&-title {
					font-size: $smaller-font-size;
					font-family: $montserrat;
					color: $gray;
				}
			}
		}
	}
	&__section {
		padding: 2rem 0;
		&.comparison {
			margin: 1rem 0;
		}
		margin: 0 3rem;
		display: flex;
		justify-content: space-between;
		background-color: $white;
		border-bottom: 0.1rem solid $gray;

		&__combine {
			padding: 2rem 0;
			margin: 0 3rem;
			border-bottom: 0.1rem solid $gray;
			display: flex;
			justify-content: space-between;
			align-items: center;
			&__title {
				&.disabled {
					color: $dark-gray;
				}
			}
		}
		&-header-text {
			@include header-2-text($black);
		}
		&-header {
			display: flex;
			align-items: center;
			.fa-question-circle {
				color: $orange;
				margin-left: 1.5rem;
			}
		}
	}
	&__summary {
		display: flex;
		width: 100%;
		&-section {
			background-color: $white;
			padding: 1.5rem 3rem;
			margin-bottom: 1rem;
			flex: 1;
		}
	}
	&__section-filter {
		margin-bottom: 1rem;
		width: 100%;
	}
	&__item-left {
		margin-right: 1.6rem;
	}

	.section-filter,
	.advance-filter__filter-group__body,
	.advance-comparison__comparison-group__body {
		.MuiInput-root {
			width: 100%;
			height: 4.5rem;
		}
		.MuiInputBase-root {
			border: 0.1rem solid $light-black;
		}
		.MuiSelect-root {
			background-color: $white;
			height: 2.2rem;
			border-width: 0;
		}
		.select-value {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	.edit-advance-filter-button {
		width: 50%;
		min-width: 12rem;
		.button-text {
			font-size: $small-font-size;
			font-family: $montserrat;
			font-weight: bold;
		}
	}

	&__open-advance-button-container {
		display: flex;
		align-items: center;
		.tooltip {
			margin-left: 1rem;
			color: $orange;
		}
	}

	.survey-answers {
		.modal-content {
			left: 0;
			right: 0;
		}
	}
}
