.survey-searchbar {
	display: flex;
	width: 100%;
	max-width: 20rem;

	.input__container .input-row input {
		background: url(../assets/images/search.png) no-repeat scroll 17rem 1.2rem;
		background-color: $white;
		border-radius: 0.5rem;
		padding-right: 4rem;
	}
}
