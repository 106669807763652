.terms-modal {
	padding: 2rem 3rem;
	margin: 5rem 0;
	position: relative;
	p,
	h1,
	h2,
	h3,
	&-container {
		margin: 0;
	}
	span {
		white-space: pre-line;
	}
	.head-title {
		text-align: center;
		margin-bottom: 1.2rem;
	}
	.terms-content {
		max-height: 70vh;
		overflow-y: scroll;
	}
	.close-icon {
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem;
	}
	.button-wrapper {
		margin-top: 1.2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		.confirm-button {
			background-color: $orange;
			min-width: 12rem;
			&-text {
				@include smaller-text($white);
			}
		}
	}
	.button-download {
		padding: 1rem 0;
		&-text {
			font-size: $small-font-size;
			text-decoration: underline;
		}
	}
}
