.dropdown-filter {
	position: absolute;
	top: 2rem;
	div &__container {
		margin: 0 1.5rem;
		z-index: 9;
	}
	&-clickable-container {
		display: flex;
		align-items: center;
		&:hover {
			cursor: pointer;
			transition: $transition-default;
			opacity: 0.7;
		}
	}
	&-clickable-text {
		@include small-text($black);
		font-weight: 500;
		margin-right: 1rem;
	}
	&-clickable-dark-grey-text {
		font-weight: 500;
		color: $darker-gray;
		text-transform: none;
		margin-right: 1rem;
	}
	&-clickable-light-gray-text {
		color: $gray;
		font-size: $normal-font-size;
		font-family: $cabin;
		font-weight: 500;
		text-transform: none;
		margin-right: 1rem;
	}
	&-clickable-orange-text {
		@include small-text($orange);
		font-weight: 500;
		margin-right: 1rem;
	}
	&-clickable-dark-blue-text {
		@include small-text($dark-blue);
		font-weight: 500;
		margin-right: 1rem;
	}
	&-clickable-gray-text {
		@include small-text($gray);
		font-weight: 500;
		margin-right: 1rem;
	}
	&__container & .dropdown-item {
		background-color: white;
		padding: 1.5em 2em;
		min-width: 0;
		&:hover {
			cursor: pointer;
			background-color: $lighter-gray;
		}
		&-content {
			@include normal-text($black);
			font-weight: 500;
		}
	}
}
