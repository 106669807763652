div .user-list-modal {
	z-index: 1000;
	& &-content {
		padding: 2rem 3rem;
		@include breakpoint(desktop) {
			left: 20%;
			right: 20%;
		}
	}
	.subtitle {
		font-size: $normal-big-font-size;
		margin-bottom: 1rem;
	}
	.clear-button {
		position: absolute;
		top: -1rem;
		right: -1rem;
		padding: 0.5rem;
	}
	.title-wrapper {
		display: flex;
		flex-direction: row;
		position: relative;
		h1 {
			margin-right: 2rem;
		}
		margin-bottom: 1rem;
	}
	.list {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		overflow-y: scroll;
		&__container-left {
			margin-right: 4rem;
		}
		&__container-right {
			padding-top: 2.1rem;
			margin-left: 4rem;
		}
	}
	.button-footer {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 1.5rem;
		.button-add {
			width: 16em;
			background-color: $orange;
		}
		.button-left {
			margin-right: 1rem;
		}
		.button-text {
			font-family: $montserrat;
			font-size: $small-font-size;
		}
		.button-disabled {
			background-color: $gray;
		}
	}
}
