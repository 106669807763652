.alert-image__container {
	background-color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 4em 6em;
	position: relative;
	border-top: 0.2rem solid $orange;
	.icon-container {
		position: absolute;
		top: 0;
		right: 0;
		padding: 1rem;
		&:hover {
			cursor: pointer;
		}
		.icon {
			color: $black;
		}
	}
	.icon {
		color: $black;
	}
	.alert-image-title {
		@include header-2-text();
		margin-bottom: 3rem;
	}
	.buttons-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
	}
}
