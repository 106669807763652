.column-data {
	width: 10%;
	max-width: 9rem;
	margin-left: 3rem;
	&-title {
		@include normal-text($light-black);
		margin-top: -1.5rem;
		text-align: center;
		&-grid {
			@include normal-text($light-black);
			margin-top: 0.4rem;
			text-align: center;
		}
	}
	&-content {
		border-left: 0.1rem solid $gray;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&-grid {
			margin-top: 0.9rem;
			border-right: 0.1rem solid $gray;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		&-item {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&-text {
				@include small-smaller-text($light-black);
			}
		}
	}
}
