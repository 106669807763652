.info-table {
	display: flex;
	width: 100%;
	&-section {
		background-color: $white;
		padding: 1.5rem 3rem;
		margin-bottom: 1rem;
		flex: 1;
	}
	.left {
		margin-right: 1.6rem;
	}
}
