$question-width: 40%;
@include breakpoint(tab-port) {
	$question-width: 30%;
}
@include breakpoint(desktop) {
	$question-width: 20%;
}

.multiple-choice-grid-preview {
	padding: $template-padding;
	&.special {
		.Component-root-1 {
			color: $white !important;
		}
	}
	&-label {
		display: flex;
		margin-bottom: 1rem;
		align-items: center;
		.label-empty {
			width: $question-width;
		}
		.label-options {
			flex: 1;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}
		.label-container {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			.label-text {
				@include normal-text($light-black);
				text-align: center;
				word-break: break-word;
				hyphens: auto;
			}
		}
	}
	.multiple-choice-row {
		display: flex;
		padding: 1rem 0;

		&.special {
			justify-content: space-between;
			@include breakpoint(phone) {
				flex-wrap: wrap;
			}
		}
		&-target {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			@include normal-text($gray);
		}
		&-options {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			&.mobile {
				flex-direction: column;
			}
			&.special {
				justify-content: space-around;
			}
			&--radio-row {
				display: flex;
				flex: 1;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				min-width: 100%;
			}
			&--radio-row:nth-child(even) {
				margin-right: -3rem;
				margin-left: -3rem;
				padding-left: 3rem;
				padding-right: 3rem;
				background-color: $light-gray;
			}
			&-fullwidth {
				min-width: 100%;
			}
		}
		&-flex {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			&.mobile {
				flex: none;
				margin-left: auto;
			}
		}
		&-text {
			@include normal-bold-text($light-black);
			width: $question-width;
			word-break: break-word;
			hyphens: auto;
			&.mobile {
				width: 100%;
			}
		}

		.MuiFormGroup-row {
			flex-direction: row;
			flex: 1;
		}
	}
	.gray {
		background-color: $white-gray;
		margin-right: -3rem;
		margin-left: -3rem;
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.white {
		background-color: $white;
	}
}
