.image-checkbox {
	display: flex;
	align-items: center;
	&__options {
		img {
			width: 25rem;
			height: 20rem;
		}
	}
}
