header {
	height: 7rem;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	//Over blue header
	z-index: 11;
}
.not-fix {
	position: initial;
}
main {
	padding-top: 13rem;
	display: block;
	width: 100%;
	padding-bottom: 5.8rem;
	height: 100%;
}
.main-not-fix {
	padding-top: 6rem;
}

footer {
	height: 5.8rem;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.hide {
	height: 0;
}
.main-hide {
	padding-top: 4rem;
}
.main-unauthenticated {
	padding-top: 7rem;
}
