.image-row {
	&__container {
		display: flex;
		justify-content: space-between;
		width: 100%;
		label {
			display: flex;
		}
	}
	&__actions {
		margin: 0 10px;
	}
}
