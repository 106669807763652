.text-chart {
	&-ellipsis {
		max-height: 63rem;
		overflow-y: scroll;
		&__more-icon {
			width: 100%;
			padding: 1rem;
			margin-top: -12rem;
			animation: {
				name: loop-y;
				duration: 1s;
				iteration-count: infinite;
				timing-function: ease-in-out;
			}
			&:hover {
				transform: scale(1.2, 1.2);
			}
			.fa-arrow-alt-circle-down {
				color: $orange;
			}
		}
	}
	&-answer {
		&-row-odd {
			background-color: $darker-white;
		}
		&-row-even {
			background-color: $white;
		}
		.text-chart &-button {
			background-color: $gray;
			&-text {
				font-size: $smaller-font-size;
				font-family: $montserrat;
				font-weight: bold;
				padding: 0.6rem 2rem;
			}
			&-container {
				margin-top: 2rem;
				max-width: 16em;
			}
		}
	}
}
