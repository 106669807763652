.image-bank-modal {
	display: flex;
	flex-direction: column;

	.fal {
		text-align: right;
		margin: 2rem;
		font-size: 2rem;
	}

	&__thumbnail {
		display: flex;
		flex-wrap: wrap;
		h2 {
			color: $medium-gray;
		}
	}

	&__cards {
		cursor: pointer;
		flex-basis: 90%;
		align-self: center;
		justify-self: center;
		border-radius: 2rem;
		&--desc {
			font-size: 1.2rem;
			margin-bottom: -1rem;
			border-top: 1px solid $light-gray;
		}
		.MuiCardActions-root {
			padding-bottom: 0;
		}
		@include breakpoint(tab-land) {
			flex-basis: 50%;
		}
		@include breakpoint(desktop) {
			flex-basis: 30%;
		}
		.makeStyles-root-1 {
			flex: 1;
		}
	}
}
