.edit-survey-thank {
	.header-button-container {
		border-radius: 2.2rem;
		border: 0.2rem solid $white;
		.fa-long-arrow-alt-left {
			margin-right: 1rem;
		}
	}
	&-default-text {
		padding: 1.4rem 1.4rem 0 1.4rem;
	}
	.header-button-text {
		font-size: $normal-biggest-font-size;
		padding: 0.6rem 3rem;
	}
	&__container {
		@include container-padding(3rem);
	}
	&-item {
		padding: 2rem 8rem 2rem 0;
	}
	& &-orange-button {
		background-color: $orange;
		max-width: 20em;
		&-text {
			font-size: $smaller-font-size;
			font-family: $montserrat;
			font-weight: bold;
		}
	}
	& .input-row &-input {
		border-color: $light-black;
		&:focus {
			border-color: $orange;
		}
	}
	& &-textarea {
		width: 100%;
		margin: 3rem 0;
		.MuiInputBase-input {
			font-weight: normal;
			&::placeholder {
				font-weight: normal;
			}
		}
	}

	&-label {
		&-text {
			@include normal-text($black);
			margin-bottom: 2rem;
		}
	}

	&-footer {
		display: flex;
		margin-top: 3rem;
		justify-content: center;
	}

	&-gray-button {
		background-color: $gray;
		&-text {
			font-size: $normal-biggest-font-size;
			padding: 1rem 5rem;
		}
	}
}
