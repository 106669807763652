.welcome {
	&__upper {
		width: 80%;
		@include breakpoint('tab-land') {
			width: 70%;
		}
		@include breakpoint('desktop') {
			width: 50%;
		}
		@include breakpoint('desktop-large') {
			width: 40%;
		}
		background-color: rgba(255, 255, 255, 0.95);
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 0.4rem;
		padding: 2rem;
		position: relative;
		.logo {
			height: 50%;
			width: auto;
			margin-bottom: 2rem;
		}
		.logo-container {
			display: flex;
			width: auto;
			justify-content: center;
			align-items: center;
			min-height: 4rem;
		}
		&-button-wrapper {
			position: absolute;
			bottom: -10rem;

			.button:nth-child(2) {
				margin-top: 1rem;
			}
			.button-primary .button-text {
				font-size: 2rem;
				font-family: $cabin;
				min-width: unset;
			}
			.button-text {
				padding: 0.9rem 5rem;
				white-space: nowrap;
			}
			.button:not(:first-child) {
				margin-left: 1rem;
			}
		}
		&-button-wrapper {
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
		&-icon {
			display: flex;
			justify-content: center;
		}
		&-subtitle {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;
			a {
				margin: 0 0.5rem;
				@include normal-text($black);
				line-height: 1.5em;
			}
			p {
				@include normal-text($black);
				line-height: 1.5em;
				text-align: center;
				margin: 0.5rem 0;
			}
			p:last-child {
				width: 100%;
				margin-bottom: 0;
			}
		}
		&-icon {
			position: absolute;
			bottom: -16rem;
			animation: {
				name: loop-y;
				duration: 1s;
				iteration-count: infinite;
				timing-function: ease-in-out;
			}
			&:hover {
				cursor: pointer;
				transform: scale(1.2, 1.2);
			}
			.fa-arrow-alt-circle-down {
				color: $white;
			}
		}
	}
	.container__background {
		width: 100vw;
		height: calc(100vw * 9 / 16);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: url('../assets/images/main-background.png');
		background-blend-mode: multiply;
		background-size: cover;
		position: relative;
		flex: 1;
	}
	.welcome-lower-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: $white;
		@include container-padding-small(10%);
		.welcome-lower-title {
			font-size: $big-title-font-size;
			font-family: $cabin;
			font-weight: bold;
			color: $black;
			margin-bottom: 3rem;
			text-align: center;
			text-transform: uppercase;
			width: 100%;
		}
		.welcome-lower-subtitle {
			font-size: $bigger-font-size;
			font-family: $montserrat;
			font-weight: bold;
			color: $black;
			text-align: center;
			margin-bottom: 4.7rem;
			width: 100%;
		}
		.welcome-lower-button-text {
			padding: 0.9rem 5rem;
		}
	}
}

@keyframes loop-y {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-1rem);
	}
}
