.tag-list {
	position: relative;
	&__container {
		overflow: scroll;
		margin: 1rem 1rem 0 1rem;
	}
	&-item {
		.PrivateSwitchBase-root-9 {
			padding: 0.3rem 0.9rem;
		}
	}
	border-top: 0.05rem solid $light-black;
	border-left: 0.05rem solid $light-black;
	border-right: 0.05rem solid $light-black;
	&__bottom {
		padding: 1rem 0;
		border-bottom: 0.05rem solid $light-black;
		&:hover {
			cursor: ns-resize;
		}
	}
	.resize-button {
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		padding: 0.5rem;
	}
	.resize-icon {
		color: $light-black;
	}
}
