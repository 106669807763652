.result-summary-item {
	background-color: $white;
	padding: 2rem 3rem 0.5rem 3rem;
	position: relative;
	flex: 1 1 100%;
	max-width: 100%;
	margin-bottom: 2.5rem;
	@include gray-border();
	border-radius: $small-border-radius;

	&-folder-title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-right: auto;
		margin-left: 1.5rem;
	}

	&.folder {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: $light-gray;
		border-radius: $small-border-radius;
		padding: 1rem 2rem 1rem 1rem;
		cursor: pointer;
		@include gray-border();
	}

	@include breakpoint(tab-land) {
		flex: 0 1 49%;
		max-width: 49%;
		margin-right: 2%;
		&:nth-child(even) {
			margin-right: 0;
		}

		&.folder {
			flex: 0 1 32.3%;
			max-width: 32.3%;
		}
	}
	@include breakpoint(desktop) {
		flex: 0 1 32.3%;
		max-width: 32.3%;
		margin-right: 1.5%;
		&:nth-child(even) {
			margin-right: 1.5%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
		&.folder {
			flex: 0 1 24.25%;
			max-width: 24.25%;
		}
	}
	@include breakpoint(desktop-large) {
		flex: 0 1 24.25%;
		max-width: 24.25%;
		margin-right: 1%;
		&:nth-child(even) {
			margin-right: 1%;
		}
		&:nth-child(3n) {
			margin-right: 1%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
		&.folder {
			flex: 0 1 19.75%;
			max-width: 19.75%;
		}
	}
	&-name {
		font-size: $normal-big-font-size;
		font-family: $montserrat;
		font-weight: 600;
		text-transform: none;
		width: 100%;
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		&.folder {
			font-size: $normal-big-font-size;
			font-weight: 400;
		}
	}
	.public-link-wrapper {
		font-size: $normal-font-size;
		height: 4.5em;
	}
	&-row {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 0;
		border-bottom: 0.05rem solid $lighter-gray;
		margin: 0.5rem 0;
		&-left {
			flex: 1;
			width: 100%;
		}
		&-right {
			padding-left: 1rem;
			flex: 1;
			width: 100%;
			overflow-y: hidden;
			position: relative;
			.button-row {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				.button-left {
					margin-right: 1rem;
				}
				.footer-button {
					background-color: $orange;
					&-text {
						padding: 0.6rem 2rem;
						font-family: $montserrat;
						font-size: $smaller-font-size;
					}
				}
			}
		}
	}
	&-normal-text {
		@include normal-text($black);
		font-weight: 400;
		width: 100%;
		flex: 1;
	}
	&-bold-text {
		@include normal-bold-text($black);
		font-weight: 600;
		text-align: right;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&-footer {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 2rem;
		position: relative;
		&.folder {
			margin-top: 0;
		}
		.footer-button-container {
			margin-right: 1rem;
			margin-bottom: 1.5rem;
			.gray {
				background-color: $gray;
				&:hover {
					cursor: not-allowed;
					opacity: 1;
				}
			}
			.blue {
				background-color: $dark-blue;
			}
			.orange {
				background-color: $orange;
			}
		}
		&-button-wrapper {
			display: flex;
		}
		.button-text {
			font-size: $normal-font-size;
			font-family: $montserrat;
			padding: 0.6rem 1.5rem;
			font-weight: 500;
		}
		&-icon {
			padding: 0.5rem;
			margin-right: -0.5rem;
			&:hover {
				cursor: pointer;
			}
		}
		.fa-ellipsis-v {
			color: $black;
		}
	}
	&-status {
		position: absolute;
		top: -1.1rem;
		right: 2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: $smaller-border-radius;
		height: 2.6rem;
		width: 10rem;
		&-text {
			@include smaller-text($white);
			font-weight: 500;
			font-size: 1.2rem;
		}
	}
	&-status-green {
		background-color: $green;
	}
	&-status-orange {
		background-color: $orange;
	}
	&-status-blue {
		background-color: $dark-blue;
	}
	&-status-gray {
		background-color: $gray;
	}
	&-pop-up-big {
		bottom: -15rem;
	}
	&-pop-up-medium {
		bottom: -9rem;
	}
	&-pop-up-small {
		bottom: -2rem;
	}
}
p.typography {
	@include smaller-text($white);
	background-color: $orange;
	padding: 0.6rem 2rem;
}
