.radio-preview__container {
	padding: $template-padding;
	.radio-row {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.input-row input {
			height: 2em;
			border-radius: 0;
			margin-left: 1rem;
			border-bottom: 0.1rem solid $gray;
		}
	}
	.radio-column {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		min-width: 5%;
		max-width: 100%;
		flex: 1;
		margin: 0.1rem 0.5rem;
		text-align: center;
		&:nth-child(even) {
			background-color: $light-gray;
		}
		@include breakpoint('phone') {
			min-width: 30%;
			max-width: 30%;
			text-align: center;
		}
	}
	.radio-label {
		@include normal-bold-text($light-black);
	}
}
