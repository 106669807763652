.survey-summary {
	background-color: $darker-white;
	&-header {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		background-color: $light-gray;
		padding: 1rem 3rem;
		& &-right-button {
			background-color: $white;
			&-text {
				font-size: $normal-biggest-font-size;
				color: $dark-blue;
			}
		}
		&-left {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			h2 {
				color: $medium-gray;
			}
		}
	}

	&__breadcrumb {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $white;
		color: $white;
		@include gray-border();
		&__left-items {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			gap: 1rem;
			h4 {
				color: $medium-gray;
				font-size: $normal-biggest-font-size;
				font-family: $cabin;
				font-weight: 500;
				text-transform: none;
				cursor: pointer;
			}
			i {
				color: $medium-gray;
			}
		}
		&__right-items {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: $medium-gray;
			&-create-button {
				background-color: $white;
				.button-text-inside {
					font-size: $normal-biggest-font-size;
					color: $medium-gray;
				}
			}
		}
	}
	&-inner-container {
		@include container-padding-extra-smallest(0);
	}
	&-filters {
		display: flex;
		justify-content: space-between;
		margin-top: 2rem;
		align-items: center;
		&-buttons {
			display: flex;
		}
		&-dropdown {
			display: flex;
			align-items: center;
			@include breakpoint('phone') {
				flex-wrap: wrap;
				.survey-searchbar {
					width: 100%;
				}
			}
		}
		@include breakpoint('phone') {
			flex-wrap: wrap;
		}
	}
	&-content {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin-right: -1.5rem;
		margin-top: 3.5rem;
		margin-bottom: 10rem;
		position: relative;
	}
	&-folders {
		display: flex;
		width: 100%;
		flex-direction: column;
		flex-wrap: wrap;
		h2 {
			width: 100%;
			text-align: center;
			font-size: $biggest-font-size;
		}
		&__items {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			gap: 1rem;
			margin-top: 2rem;
			@include breakpoint(tab-land) {
				justify-content: unset;
			}
		}
	}
	&-filter-item {
		position: relative;
		outline: none;
		&:hover {
			cursor: pointer;
		}
		&-text {
			color: $white;
		}
		&-active {
			&:before {
				content: '';
				position: absolute;
				width: 60%;
				height: 0.2rem;
				bottom: -0.5rem;
				left: 20%;
				border-bottom: 0.2rem solid $white;
			}
		}
	}
	.filter-button-text {
		font-size: $normal-font-size;
		font-family: $montserrat;
		text-transform: none;
		font-weight: 500;
		padding: 0.9rem 1rem;
	}
	.gray-button {
		background-color: $button-gray;
		.button-text {
			color: $darker-gray;
		}
	}
	.blue-button {
		background-color: $dark-blue;
		.button-text {
			color: $white;
		}
	}
	.left-button-container {
		margin-right: 2rem;
	}
	&-separator {
		width: 0.1rem;
		height: 2rem;
		background-color: $white;
		margin: 0 1.5rem;
	}
	.alert-button {
		margin-right: 1rem;
		&-text {
			font-size: $normal-font-size;
			padding: 0.9rem 4rem;
		}
	}
	.alert-orange-button {
		background-color: $orange;
	}
}
