.create-noti {
	background-color: $darker-white;
	.header-text {
		color: $white;
	}
	&__container {
		@include container-margin(4rem);
		padding: 2rem 3rem;
		background-color: $white;
	}
	.MuiInput-root {
		width: 100%;
	}
	&__item {
		margin-bottom: 2rem;
		.item-title {
			@include normal-bold-text($black);
			margin: 0.5rem 0;
		}
		.item-text-area {
			width: 100%;
		}
	}
	&__footer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		.button-text {
			@include smaller-text($white);
		}
		.button-send {
			background-color: $orange;
		}
		.button-disabled {
			background-color: $gray;
		}
		.button-container {
			width: 12rem;
		}
		.button-left {
			margin-right: 1rem;
		}
	}
	&__switch {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin-bottom: 2rem;
		.switch-text {
			margin-right: 1rem;
		}
		.switch {
			margin-right: 0;
		}
	}
	.alert-button-text {
		@include normal-text(white);
	}
	.alert-button {
		background-color: $orange;
		width: 12rem;
	}
}
