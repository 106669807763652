.table-line {
	display: flex;
	align-items: center;
	&-order {
		@include normal-text($orange);
		width: 3em;
	}
	&-main {
		border-top: 0.1rem solid $orange;
		display: flex;
		flex: 1;
		&-last {
			border-bottom: 0.1rem solid $orange;
		}
		&-title {
			width: 20%;
			@include breakpoint(desktop-large) {
				width: 30%;
			}
			@media print {
				width: 30%;
			}
			padding: 1rem 1rem 1rem 0;
			border-right: 0.1rem solid $gray;
			&-text {
				@include normal-text($light-black);
				word-break: break-word;
				hyphens: auto;
			}
		}
	}
	.row-result {
		flex: 1;
		display: flex;
		flex-direction: column;
		border-right: 0.1rem solid $gray;
		&-wrapper {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		&-count {
			@include smaller-text($light-black);
			text-align: center;
			&-wrapper {
				background-color: $dark-alto-gray;
			}
		}
		&-percentage {
			flex: 1;
			text-align: center;
			@include smaller-text($light-black);
		}
	}
	&-column {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		border-right: 0.1rem solid $gray;
	}
	&-normal-text {
		@include smaller-text($light-black);
	}
}
