.section__container {
	border-left: 0.3rem solid $orange;
	display: flex;
	flex-direction: column;
	background-color: $white;
	margin-bottom: 2.5rem;
	position: relative;
	&-border-top {
		border-top: 0.3rem solid $orange;
	}
	.section-title {
		padding: 1rem 3rem;
		width: 100%;
		background-color: $orange;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&-text {
			@include normal-bold-text($white);
		}
		.fa-lock {
			color: $white;
		}
	}
	.lower-container {
		padding: 2rem 3rem;
	}
}
