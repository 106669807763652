.radio-button__container {
	display: flex;
	flex-direction: row;
	align-items: center;
	.radio-button-label {
		@include normal-bold-text();
	}
	&.result-item {
		padding: 2rem;
		margin: 0 1rem;
	}
}
