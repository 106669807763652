.list-copy {
	background-color: $white;
	padding: 3rem 3rem;
	margin-bottom: 3rem;
	@include breakpoint(tab-land) {
		padding: 2rem 5%;
	}
	@include breakpoint(desktop) {
		padding: 2rem 15%;
	}
	.toolbar__button {
		.fa-plus-circle {
			color: $orange;
		}
	}
	&-input {
		border-color: $light-black;
		border-radius: 0;
		@include normal-text($black);
		&:hover {
			border-color: $orange;
		}

		&::placeholder {
			@include normal-text($gray);
		}
	}
	&-title {
		@include header-2-text($black);
		margin-bottom: 1rem;
	}
	&-subtitle {
		@include normal-text($black);
		margin-bottom: 2rem;
	}
	div &-textarea {
		width: 100%;
		.MuiInputBase-input {
			font-weight: normal;
		}
	}
	label {
		margin-bottom: 3rem;
	}
	&-textarea-input {
		font-weight: 400;
		@include normal-text($black);
	}
	.button-container {
		display: flex;
		.list-copy-button-save {
			background-color: $orange;
			&-disabled {
				background-color: $gray;
				&:hover {
					cursor: not-allowed;
					opacity: 1;
				}
			}
			&-text {
				font-size: $normal-biggest-font-size;
			}
		}
		.list-copy-button-save-container {
			width: 20em;
		}
		.list-copy-button-cancel {
			background-color: $gray;
			&-text {
				font-size: $normal-biggest-font-size;
			}
		}
		.list-copy-button-cancel-container {
			width: 20em;
			margin-right: 3rem;
		}
	}
	.error-message {
		margin-top: 1rem;
		@include normal-text($red);
	}
}
