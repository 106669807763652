.advance-filter,
.advance-comparison {
	&__modal {
		&-container.modal-container {
			margin: unset;
		}

		margin: 5rem 0;
		max-height: calc(100vh - 5rem * 2);
		overflow-y: scroll;

		.close-button {
			position: absolute;
			top: 0;
			right: 0;
			background-color: transparent;
			justify-content: flex-end;
		}

		.tooltip {
			color: $orange;
			margin-left: 0.5rem;
		}
		&-header {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			margin-bottom: 1.2rem;
			color: $black;
			border-bottom: 0.1rem solid $white-gray;
			padding: 2rem 3rem 1rem;

			.close-button {
				padding: 1.5rem 1rem;
				width: unset;
			}
		}
		&-body-wrapper {
			padding: 2rem 3rem;

			max-height: 600px;
			overflow-y: scroll;
			.sub-header {
				display: flex;
				align-items: center;
				margin-bottom: 2rem;
			}
		}
		&-footer {
			display: flex;
			justify-content: flex-end;
			// padding: 1rem 3rem 2rem;
			.button {
				margin-left: 0.5rem;
			}
		}
		.section-filter-footer {
			&__button-wrapper {
				display: flex;
				justify-content: flex-end;
			}
			&__and-indicator {
				color: $orange;
				text-transform: uppercase;
				font-weight: bold;
				margin: 1rem -2rem;
				background-color: $white-light-gray;
				padding: 0.5rem 2rem;
			}
		}
		.add-new-group-button {
			justify-content: flex-start;
		}
	}
	&__filter-group,
	&__comparison-group {
		background-color: $darker-white;
		margin-bottom: 2rem;
		&__header,
		&__footer {
			background-color: $white-light-gray;
			color: $light-black;
			font-family: $montserrat;
			padding: 2rem;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.remove-group-filter-button {
				background-color: $white;
				padding: 0.6rem 2rem;
				border-radius: 3rem;

				.icon {
					font-size: 2rem;
				}
			}
		}

		&__footer {
			.extra-filter-button {
				font-size: $small-font-size;
			}
		}

		&__header {
			.input__container .input-row &-input.input-no-border {
				background-color: $white-light-gray;
				height: unset;
				border-bottom: 0.2rem solid $dark-alto-gray;
				text-transform: uppercase;
				font-size: 1.6rem;
				color: $light-black;
				font-family: $montserrat;
				font-weight: bold !important;
				letter-spacing: 0.1rem;
				padding: 0.5rem;
				max-width: 90%;
			}
		}
	}
	&__section-filter {
		margin: 2rem;

		&__header {
			color: $light-black;
			font-family: $montserrat;
			margin-bottom: 2rem;
			margin-top: unset;
			position: relative;
			.close-icon {
				padding: 0;
			}
		}
	}
}

.advance-comparison {
	&__modal-body-wrapper {
		max-height: 600px;
		overflow-y: scroll;
		.section-filter {
			padding: unset;
			border-bottom: 0.1rem solid $white-gray;
		}
	}

	&__comparison-group {
		margin-top: 2rem;
		&__header {
			&-wrapper {
				flex: 1;
			}
			&-title {
				font-family: $montserrat;
				font-size: 1.4rem;
			}
			.input__container .input-row &-input.input-no-border {
				font-size: 1.4rem;
				padding-left: 0;
				font-weight: lighter !important;
			}
		}

		&__body {
			display: flex;
			padding: 2rem;
			&-left {
				flex: 1;
				margin-right: 2rem;

				.result-detail__section {
					background-color: unset;
					padding: 0;
					margin: 0;
					border-bottom: unset;
				}
			}
			&-right {
				flex: 2;
			}
		}
	}
}
