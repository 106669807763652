.nav-bar-item__container {
	background-color: $white;
	padding: 1em 5em 1em 2em;
	border-bottom: 0.1rem solid $dark-gray;
	position: relative;
	width: 100%;
	@include breakpoint('tab-land') {
		text-transform: uppercase;
		padding: 0 1em;
		position: relative;
		border-width: 0;
		width: auto;
		&:hover {
			opacity: 0.7;
		}
	}
	&:hover {
		cursor: pointer;
		transition: $transition-default;
	}
	&:hover:before {
		content: '';
		position: absolute;
		width: 85%;
		height: 0.2rem;
		bottom: -0.5rem;
		left: 7.5%;
		border-bottom: 0.2rem solid $gray;
	}
	&_active:before {
		content: '';
		position: absolute;
		width: 85%;
		height: 0.2rem;
		bottom: -0.5rem;
		left: 7.5%;
		border-bottom: 0.2rem solid $orange;
	}
	.nav-bar-item-image-2 {
		position: absolute;
		bottom: -6rem;
		left: 8rem;
	}
	.nav-bar-item-image-3 {
		position: absolute;
		bottom: -8rem;
		left: 7rem;
	}

	.nav-bar-item-image-4 {
		position: absolute;
		bottom: -10rem;
		left: 3rem;
	}
	.nav-bar-item-image-5 {
		position: absolute;
		bottom: -8rem;
		left: 8rem;
	}

	.nav-bar-item-text {
		@include header-3-text($dark-gray);
		text-align: left;
		@include breakpoint('tab-land') {
			text-align: center;
		}
		&:hover {
			color: $light-black;
			transition: $transition-default;
		}
	}
	.nav-bar-item-text-active {
		text-align: left;
		@include breakpoint('tab-land') {
			text-align: center;
		}
		@include header-3-text($light-black);
	}
}

.nav-bar-item-focus {
	background-color: $white;
	border-radius: 2rem;
	padding: 1rem 3rem;
	.nav-bar-item-text {
		@include header-3-text($light-black);
	}
	z-index: 1000000001; //above modal
	&:hover {
		cursor: default;
		opacity: 1;
	}
}
