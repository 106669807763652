.extra-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	:hover {
		cursor: pointer;
		.MuiIconButton-root.Mui-disabled {
			color: $orange;
		}
	}
	&.disabled {
		.MuiIconButton-root.Mui-disabled {
			color: rgba(0, 0, 0, 0.26);
		}
		cursor: not-allowed;
	}
	&-add {
		display: flex;
		align-items: center;
		width: 100%;
		span {
			@include normal-text($gray);
			text-align: left;
			width: 100%;
			max-width: 10em;
		}
		position: relative;
		&:hover {
			span {
				color: $orange;
			}
		}
		&-disabled {
			&:hover {
				cursor: not-allowed;
				span {
					color: $gray;
				}
			}
		}

		margin-right: 0.5rem;
	}
	&-span {
		@include normal-text($gray);
		margin-right: 0.5rem;
	}
	&-button {
		&:hover {
			cursor: pointer;
		}
		&-span {
			@include normal-bold-text($orange);
			text-transform: uppercase;
		}
		&-disabled {
			&:hover {
				cursor: not-allowed;
			}
		}
	}
	& &-input-container {
		width: 100%;
		.input-row input {
			border-color: $gray;
			border-radius: 0;
			height: 1.2em;
		}
	}
}
