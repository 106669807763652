.checkbox {
	display: flex;
	align-items: center;
	&-label {
		@include normal-text($black);
		&:hover {
			cursor: pointer;
		}
	}
	.Mui-disabled {
		color: $gray !important;
	}
}
