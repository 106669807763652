.form-print-section {
	border-left-width: 0;
	border-top-width: 0;
}
.print-survey {
	padding: 2rem 1rem 1rem 1rem;
	max-width: 1024px;
	width: 1024px;
	margin: auto;
	.print-survey-title {
		padding: 2rem 3rem;
		background-color: white;
		margin-bottom: 3rem;
	}
}

.page-print-screenshot {
	min-height: unset !important;
	height: fit-content;
	width: fit-content;
	.error-message-container {
		display: none;
	}
	.main-hide {
		padding: 0;
	}
	.print-survey {
		padding: 0;
		margin: 0;
	}
	.error-message-container {
		display: none;
	}
	.main-hide {
		padding: 0;
	}
	.print-survey {
		padding: 0;
		margin: 0;
	}

	.print-survey-title {
		display: none;
	}

	.result-table {
		display: none;
	}
}
.page-print-pdf {
	.ellipsis-text {
		max-height: unset;
		+ .ellipsis-button {
			display: none;
		}
	}
}
