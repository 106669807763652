.edit-survey-info {
	.header-button-container {
		border-radius: 2.2rem;
		border: 0.2rem solid $white;
		.fa-long-arrow-alt-left {
			margin-right: 1rem;
		}
	}
	.header-button-text {
		font-size: $normal-biggest-font-size;
		padding: 0.6rem 2rem;
	}
	&-label {
		width: 100%;
		&-text {
			@include normal-text($black);
			margin-bottom: 2rem;
		}
	}
	.edit-survey-info-inner-container {
		@include container-padding(3rem);
		background-color: $lighter-gray;
		.edit-survey-info-head-title {
			@include header-2-text($black);
			margin-bottom: 3rem;
		}
		.section-row {
			display: flex;
			align-items: center;
			margin-bottom: 1.5rem;
			.edit-public-link {
				&-text {
					@include normal-text($black);
					text-decoration: underline;
				}
				&-disabled {
					&:hover {
						cursor: default;
					}
				}
				&-enabled {
					&:hover {
						cursor: pointer;
					}
				}
			}

			.public-link-input-container {
				flex: 1;
				margin: 0 1.5rem;
			}
			.public-link-input {
				border-radius: 0;
				height: 1em;
				&:focus {
					border-bottom: 0.1rem solid $orange;
				}
			}
			.section-switch-text {
				@include normal-bold-text($black);
			}
		}
		.edit-survey-info-item {
			padding: 2rem 8rem 2rem 0;
			.MuiSelect-root {
				display: flex;
				align-items: center;
			}
		}
		.edit-survey-info-normal-text {
			@include normal-text($black);
			margin-bottom: 2rem;
		}
		.edit-survey-info-input {
			border-radius: 0;
			border-color: $light-black;
			@include normal-text($black);
			&::placeholder {
				@include normal-text($gray);
			}
		}
		.edit-survey-info-orange-button {
			background-color: $orange;
			max-width: 20em;
			&-text {
				@include normal-bold-text($white);
			}
		}
		.MuiInputBase-root {
			width: 100%;
			margin-bottom: 2.5rem;
			.MuiSelect-root {
				height: 1.5em;
				background-color: $white;
				border: 0.05rem solid $light-black;
			}
		}
		.edit-survey-info-textarea {
			width: 100%;
			margin-bottom: 2.5rem;
			.MuiInputBase-input {
				font-weight: normal;
				&::placeholder {
					font-weight: normal;
				}
			}
		}
		.edit-survey-info-footer {
			display: flex;
			margin-top: 3rem;
			justify-content: center;
		}
		.ready-button {
			&-text {
				font-size: $normal-biggest-font-size;
				padding: 1rem 5rem;
			}
		}
		.button-disabled {
			background-color: $gray;
		}
	}
	&--max-answers {
		margin-left: auto;
	}
}
