.time-picker {
	display: flex;
	flex-direction: column;
	.MuiInputBase-root {
		margin-top: 3em;
		border: 0.05rem solid $light-black;
		padding: 0.5em 0 0.5em 1em;
	}
	.Mui-disabled {
		border-color: $gray;
	}
	.MuiFormControl-root {
		label {
			@include normal-text($black);
		}
		.MuiFormLabel-root {
			line-height: 1.2em;
		}

		.Mui-focused {
			border-color: $orange;
		}
		.MuiInputBase-input {
			@include normal-text($black);
			&:disabled {
				color: $gray;
			}
		}
	}
	&-hint {
		margin-top: 1rem;
		@include small-text($gray);
	}
}
