.survey-answers {
	&__modal {
		height: 90vh;
		border-left: solid 2px $orange;
		padding: 3rem;

		&--close-icon {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-bottom: 1rem;
			i {
				font-size: 1.3rem;
				color: $light-black;
			}

			input {
				max-width: 40rem;
			}
		}

		&--search {
			display: flex;

			.input__container .input-row input {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				border-right: none;
			}

			button {
				border-radius: 0;
				height: 4.2rem;
				background-color: $orange;

				.icon {
					color: $white;
					font-size: 1.5rem;
					margin-left: 0;
				}
			}
		}

		.MuiDataGrid-root {
			border: none;
			color: $light-black;
			font-size: 1.2rem;
			border: solid 0.5px $gray;
			height: 75vh;
			.MuiDataGrid-colCell {
				border-left: solid 1px $white;
				font-size: 1.4rem;
			}

			.MuiDataGrid-colCellTitle,
			.MuiDataGrid-sortIcon {
				color: $white;
			}

			.MuiDataGrid-iconSeparator {
				display: none;
			}
			// To truncate long text ...
			.MuiDataGrid-cellLeft {
				display: block;
			}

			.MuiDataGrid-row {
				&:hover {
					background-color: $orange-white;
				}
			}

			.MuiDataGrid-cell {
				padding: 0 1rem;
			}
		}

		i {
			cursor: pointer;
		}

		.MuiDataGrid-colCellWrapper {
			background-color: $dark-blue;
		}

		&-alert .modal .modal-container .modal-content {
			left: 20%;
			right: 20%;
		}
	}

	.Mui-odd {
		background-color: $alto-gray;
	}
}
