.user-list {
	max-height: 50rem;
	padding-left: 1rem;
	margin-top: 2rem;
	.check-box {
		height: 3rem;
	}
	.user-item {
		display: flex;
		align-items: center;
		height: 3rem;
	}
}
