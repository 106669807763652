.date-template {
	padding: $template-padding;
	margin-bottom: 3rem;
	&-picker {
		width: 100%;
		max-width: 36rem;
		& .MuiFormControl-root {
			.Mui-disabled {
				border-color: $gray;
				color: $gray;
			}
		}
	}
}
