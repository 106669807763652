.pop-up__container {
	z-index: 100;
	position: absolute;
	bottom: -60%;
	right: 10%;
	background-color: $white;
	@include breakpoint(tab-land) {
		right: -10%;
	}
	&.folder-pop-up {
		top: 0%;
		right: -10%;
		z-index: 100;
	}
	@include box-shadow-default();
	.pop-up-item {
		background-color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2rem;
		width: 100%;
		&:hover {
			cursor: pointer;
			background-color: $lighter-gray;
		}
		&-text {
			text-align: center;
			@include normal-bold-text($black);
			text-transform: uppercase;
			&::after {
				content: '';
				width: 2rem;
				height: 0.1rem;
				background-color: $gray;
			}
			&.disabled {
				color: $dark-gray;
			}
		}
		&.disabled {
			cursor: not-allowed;
		}
	}
}
.pop-up-show {
	animation: {
		name: dropdown;
		duration: 200ms;
		timing-function: ease-in-out;
		fill-mode: forwards;
	}
}
.pop-up-hide {
	visibility: hidden;
}
