.new-survey {
	position: relative;
	&-float {
		margin-top: 7rem;
	}
	&__container {
		padding: 3rem 2.5%;
		width: 100%;
		margin-top: 2rem;
	}
	.alert-container {
		margin-top: 15%;
		@include breakpoint(desktop-large) {
			margin-top: 10%;
		}
		.alert-button {
			background-color: $orange;
			min-width: 14rem;
		}
		.alert-footer {
			margin-left: 6em;
			margin-bottom: 2rem;
		}
	}
	&__header {
		&-fixed {
			position: fixed;
			top: 0;
			width: 100%;
			z-index: 10;
		}
	}

	.header-title {
		display: flex;
		align-items: center;
		gap: 1rem;
		i {
			color: $medium-gray;
		}
		&-button {
			margin-right: 0.5rem;
			&:hover {
				cursor: pointer;
			}
		}
		&-text {
			color: $medium-gray;
			font-size: $normal-biggest-font-size;
			font-family: $cabin;
			font-weight: 500;
			text-transform: none;
		}
	}
	&-4-grid {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 3.5rem;
	}
	&-6-grid {
		display: grid;
		gap: 2rem;
		grid-template-columns: repeat(3, 1fr);
		@include breakpoint(phone) {
			grid-template-columns: repeat(1, 1fr);
		}
		@include breakpoint(tab-port) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include breakpoint(tab-land) {
			grid-template-columns: repeat(2, 1fr);
		}
		@include breakpoint(desktop) {
			grid-template-columns: repeat(4, 1fr);
		}
		@include breakpoint(desktop-large) {
			grid-template-columns: repeat(5, 1fr);
		}
		width: 100%;
		margin-top: 3rem;
		margin-bottom: 5rem;
	}
	&-info {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 2em;
		border: 1px solid #66bbff;
		border-radius: $small-border-radius;
		padding: 2rem;
		.icon {
			color: #006cc2;
			margin-right: 1em;
			font-size: large;
		}
		.text {
			@include normal-text(#006cc2);
		}
	}
}
