.organisational-modal {
	padding: 1rem;

	&__checkbox {
		padding: 5rem;
	}

	&__button {
		display: flex;
		justify-content: center;
		.button {
			margin: 0.5rem;
		}

		.button-continue {
			background-color: $orange;
		}
	}
}
