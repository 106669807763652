.edit-link {
	div &__modal {
		margin: 10%;
	}
	&-header {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 6rem;
		background-color: $dark-blue;
		&-text {
			@include header-2-text($white);
		}
	}
	&-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 2em;
		background-color: $white;
		&-row {
			display: flex;
			align-items: center;
		}
		&-row-start {
			display: flex;
		}
		&-header-text {
			@include normal-text();
			text-transform: uppercase;
			margin-bottom: 1.5rem;
		}
		&-normal-text {
			@include normal-text($black);
			margin-bottom: 3rem;
		}
		&-bold-text {
			@include normal-bold-text($black);
		}
		&-default-text {
			margin-top: 1em;
		}
		& &-input-container {
			margin-left: 1rem;
		}
		& .input__container .input-row &-input {
			border-color: $light-black;
			border-radius: 0;
			height: 2.5em;
			&:focus {
				border-color: $orange;
			}
		}
	}
	&-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 2.5rem;
	}
	&-button {
		&-container {
			max-width: 16em;
		}
		&-left {
			margin-right: 1.6rem;
		}
		&-container &-orange {
			background-color: $orange;
		}
		&-container &-gray {
			background-color: $gray;
		}
		&-text {
			font-size: $normal-biggest-font-size;
			padding: 0.6rem 3rem;
		}
		&-container &-disabled {
			background-color: $gray;
			&:hover {
				cursor: not-allowed;
				opacity: 1;
			}
		}
	}
}
