.form-dashboard {
	background-color: $light-gray;
	div.header-left {
		display: flex;
		align-items: center;
		.fa-long-arrow-alt-left {
			color: $medium-gray;
			margin-right: 1rem;
		}
		.header-back-button {
			display: flex;
			align-items: center;
			&:hover {
				cursor: pointer;
			}
			.fa-long-arrow-alt-left {
				font-size: $normal-big-font-size;
			}
		}
		.header-text {
			@include header-2-text($medium-gray);
			margin-right: 1.5rem;
		}
		.oval {
			&.green {
				background-color: $green;
			}
			&.gray {
				background-color: $gray;
			}
			&.orange {
				background-color: $orange;
			}
		}

		.header-separator {
			background-color: $medium-gray;
			width: 0.2rem;
			height: 3rem;
			margin-right: 1.5rem;
		}
		button.header-button {
			background-color: $medium-gray;
		}
		button .header-button-text {
			font-size: $smaller-font-size;
			font-family: $montserrat;
			color: $dark-blue;
			font-weight: bold;
			padding: 0.6rem 1rem;
		}
	}
	div.header-right {
		display: flex;
		justify-content: flex-end;

		button.button-white {
			background-color: $white;
			margin-right: 2rem;
		}
		button .button-white-text {
			@include header-4-text($light-black);
		}
		button.button-orange {
			background-color: $orange;
		}
		button .button-orange-text {
			@include header-4-text($white);
		}
	}
	& &-modal {
		margin-top: 10%;
		.button-disabled {
			background-color: $gray;
		}
		&__print-modal {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			max-width: 50rem;
			margin: 2rem auto;
			padding: 2rem;
			min-height: 20rem;
			h3 {
				margin-bottom: 1rem;
			}
			p {
				margin-bottom: 2rem;
			}
			&__landscape-switch__container {
				p {
					margin: 2rem 0 1rem;
				}
				margin: 2rem 0;
				&__holder {
					display: flex;
					flex-direction: row;
				}
			}
			&__buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-around;
			}
			&__cancel-button {
				margin-left: auto;
				margin-top: 4rem;
			}
		}
	}
	&-time-picker-container {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		align-items: flex-start;
		.picker-item {
			flex: 0 1 100%;
			max-width: 100%;
			margin-bottom: 2%;
			@include breakpoint(tab-port) {
				grid-template-columns: repeat(2, 1fr);
				flex: 0 1 49%;
				max-width: 49%;
				margin-right: 2%;
				&:nth-child(2) {
					margin-right: 0;
				}
				&:nth-child(5) {
					margin-right: 0;
				}
			}
			@include breakpoint(desktop) {
				flex: 0 1 21%;
				max-width: 21%;
				margin-right: 2%;
				&:nth-child(even) {
					margin-right: 2%;
				}
				&:nth-child(4n) {
					margin-right: 2%;
				}
			}
		}
	}
	&-time-picker-separator {
		display: none;
		@include breakpoint(desktop) {
			display: flex;
			width: 6%;
			justify-content: center;
			align-items: center;
			margin: 2rem 2% 0 0;
			span {
				font-size: 5rem;
			}
		}
	}
	.time-picker-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
	&-time-picker-title {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;

		&-text {
			margin-right: 2rem;
		}
		&-icon {
			margin-left: 1.5rem;
			padding: 0.5rem;
			margin-top: 0.3rem;
			&:hover {
				cursor: pointer;
			}
			.fa-question-circle {
				color: $orange;
			}
		}
	}
	.picker-save-button {
		background-color: $orange;
		&.disabled {
			background-color: $gray;
		}
		&-text {
			@include smaller-text($white);
			text-transform: uppercase;
			padding: 0.6rem 2rem;
		}
		&-container {
			margin-top: 1rem;
			width: 12em;
			justify-self: flex-end;
			align-self: flex-end;
			justify-content: flex-end;
			align-items: flex-end;
		}
	}
	&-inner-container {
		@include container-padding(4rem);
		.delete-answer-footer {
			margin-top: 1rem;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			.delete-answer-button {
				@include small-button($dark-blue, $white);
			}
		}
		.section-row {
			display: flex;
			align-items: center;
			margin-bottom: 1.5rem;
			.edit-public-link {
				&-enabled {
					visibility: visible;
				}
				&-disabled {
					visibility: hidden;
				}
				&-normal-text {
					@include normal-text();
					margin-left: 1rem;
				}
				&-orange-text {
					@include normal-text($orange);
					margin-left: 1rem;
				}
				&-upper {
					margin-bottom: 1rem;
				}
				.fa-copy {
					color: $orange;
				}
			}
			.disabled-text {
				color: $gray;
			}
			.disabled {
				flex: unset;
				&:hover {
					cursor: not-allowed;
				}
			}
			.enabled {
				flex: 1;
				&:hover {
					cursor: pointer;
				}
			}

			.section-switch-text {
				@include normal-bold-text($black);
			}
		}
		.form-survey-title {
			@include header-2-text();
			margin-bottom: 1rem;
		}

		.dashboard {
			background-color: $white;
		}
		.content-container {
			.content-row {
				display: flex;
				align-items: center;
				margin-bottom: 1.5rem;
			}
			.content-survey {
				display: flex;
				align-items: center;
			}
			.content-title {
				@include header-3-text($medium-gray);
				margin-bottom: 1rem;
				text-transform: none;
			}
			.content-title-inline {
				font-size: $big-font-size;
				font-family: $cabin;
				font-weight: bold;
				color: $medium-gray;
				margin-right: 1.6rem;
			}
			.content-answer-inline {
				@include normal-text($light-black);
			}
			.content-answer {
				@include normal-text($light-black);
				margin-bottom: 1.5rem;
				white-space: pre-line;
			}
			.button-preview {
				background-color: $orange;
				max-width: 15rem;
			}
		}
	}
	.reminder-wrapper {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.fa-check-circle {
			color: $green;
			margin-right: 1rem;
		}
		.wrapper {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.text-wrapper {
			margin-left: 8rem;
			width: 100%;
			.text {
				font-size: $smaller-font-size;
			}
		}
		.button-reminder-wrapper {
			width: 100%;
			justify-content: flex-end;
		}
		.button-reminder {
			background-color: $orange;
			&-text {
				@include smaller-text($white);
				text-transform: uppercase;
				padding: 0.6rem 2rem;
			}
		}
		.button-add-more {
			background-color: $orange;
			margin-left: 1rem;
		}
		.button-disabled {
			background-color: $gray;
		}
	}

	&-footer {
		display: flex;
		justify-content: center;
		margin-top: 3rem;
		flex-wrap: wrap;
		.form-dashboard-default-button {
			width: 15rem;
			margin-bottom: 1rem;
		}
		.form-dashboard-green-button {
			background-color: $green;
			width: 15rem;
			margin-bottom: 1rem;
		}
		.form-dashboard-orange-button {
			background-color: $orange;
			width: 15rem;
			margin-bottom: 1rem;
			max-width: 15rem;
		}
		.form-dashboard-footer-button-left {
			margin-right: 2rem;
		}
		.form-dashboard-disabled-button {
			background-color: $gray;
			&:hover {
				cursor: not-allowed;
				opacity: 1;
			}
		}
		.button-text {
			font-size: $normal-big-font-size;
		}
	}
	.button-link-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&-two {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
		}
	}
	&-row {
		display: flex;
		align-items: flex-start;
	}
	&-copy-button {
		margin-left: 2rem;
	}
	&-hint {
		align-items: center;
		justify-content: center;
		margin-top: 3rem;
		height: 3em;

		p {
			text-align: center;
		}
	}
}

.form-dashboard-normal-text {
	@include normal-text($black);
}

.form-dashboard-normal-bold-text {
	@include normal-bold-text($black);
	margin-bottom: 1rem;
}

.form-dashboard-announce-text {
	@include normal-text($orange);
	margin-bottom: 1rem;
}

.form-dashboard-alert-image {
	width: 80%;
	margin-bottom: 3rem;
	@include breakpoint(tab-land) {
		width: 50%;
	}
	@include breakpoint(desktop) {
		width: 30%;
	}
}

.form-dashboard-margin-bottom {
	margin-bottom: 4rem;
}

.buttons-container .form-dashboard-alert-button {
	width: 14rem;
	background-color: $orange;
	&-text {
		font-size: $normal-biggest-font-size;
		padding: 0.9rem 5rem;
		&-schedule {
			padding: 0.9rem 0rem;
			font-size: $normal-big-font-size;
		}
	}
}

.buttons-container .cancel-button {
	width: 14rem;
	margin-right: 2rem;
	&-text {
		font-size: $normal-biggest-font-size;
		padding: 0.9rem 5rem;
		&-schedule {
			padding: 0.9rem 0rem;
			font-size: $normal-big-font-size;
		}
	}
}

.buttons-container .form-dashboard-normal-alert-button {
	background-color: $orange;
	&-text {
		font-size: $normal-biggest-font-size;
		padding: 0.9rem 5rem;
	}
}
