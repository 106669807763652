.thank-preview__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background-color: $darker-white;
	.thank-preview-header {
		padding: 1.5rem 3rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		div &-left {
			flex: 1;
			&-button-container {
				max-width: 16rem;
			}
			&-button {
				border: 0.2rem solid $white;
			}
			&-button-text {
				font-family: $cabin;
				color: $white;
				font-size: $normal-biggest-font-size;
			}
			.fa-long-arrow-left {
				color: $white;
				margin-right: 0.7rem;
			}
		}
		&-center {
			display: flex;
			justify-content: center;
			&-text {
				@include header-2-text($white);
			}
		}
		&-right {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			&-icon {
				margin-right: -1rem;
				padding: 1rem;
				&:hover {
					cursor: pointer;
				}
				.fa-times {
					color: $white;
				}
			}
		}
	}
	.thank-preview-inner-container {
		@include container-padding(3rem);

		width: 100%;
		.thank-preview-survey-title {
			background-color: $white;
			padding: 2rem 3rem;
			&-text {
				@include header-2-text($black);
			}
		}
		.thank-preview-content {
			border-top: 0.2rem solid $orange;
			margin-top: 5rem;
			flex: 1;
			padding: 2rem 3rem 8rem 3rem;
			background-color: white;
			margin-bottom: 3rem;
			.thank-preview-bold-text {
				@include header-2-text($black);
				margin-bottom: 2rem;
			}
			.thank-preview-normal-text {
				@include normal-text($black);
				white-space: pre-line;
			}

			.thank-preview-orange-button-text {
				font-size: $normal-biggest-font-size;
			}
		}
	}
}
