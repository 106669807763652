.stepper__container {
	display: flex;
	.fa-circle {
		margin: 0 0.4rem;
	}
}
.stepper__item {
	&:hover {
		cursor: pointer;
	}
	&-icon-active {
		color: $orange;
	}
	&-icon-inactive {
		color: $gray;
	}
}
