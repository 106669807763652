.header {
	width: 95%;
	background-color: $white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 6rem;
	margin: 2rem 2.5%;
	padding: 8px 24px;
	@include gray-border();
	border-radius: $small-border-radius;
	div.header-left {
		display: flex;
		align-items: center;
		.fa-long-arrow-alt-left {
			color: $dark-gray;
			margin-right: 1rem;
		}
		.header-back-button {
			display: flex;
			align-items: center;
			&:hover {
				cursor: pointer;
			}
			.fa-long-arrow-alt-left {
				font-size: $normal-big-font-size;
			}
		}
		.header-text {
			@include header-2-text($dark-gray);
			margin-right: 1.5rem;
		}

		.header-separator {
			background-color: $dark-gray;
			width: 0.2rem;
			height: 3rem;
			margin-right: 1.5rem;
		}
		button.header-button {
			background-color: $dark-gray;
		}
		button .header-button-text {
			font-size: $smaller-font-size;
			font-family: $cabin;
			color: $dark-gray;
			font-weight: bold;
			padding: 0.6rem 1rem;
		}
	}
	div.header-right {
		display: flex;
		justify-content: flex-end;

		.fa-info-circle {
			color: $orange;
		}
		button.button-white {
			background-color: $white;
			margin-right: 2rem;
		}
		button .button-white-text {
			@include header-4-text($dark-gray);
		}
		button.button-orange {
			background-color: $orange;
		}
		button .button-orange-text {
			@include header-4-text($dark-gray);
		}
	}
}

.header__container {
	display: flex;
	width: 100%;
	position: fixed;
	top: 7rem;
	z-index: 10;
	background-color: $light-gray;
}
