.translations-modal {
	&__content {
		left: 0 !important;
		right: 0 !important;
	}
	&--button-border {
		border: 1px solid $light-blue !important;
	}

	padding: 30px;
	display: flex;
	flex-direction: column;
	&__original-texts {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		border: 2px solid black;
		border-radius: 4px;
		padding: 2rem;
		&--values {
			display: flex;
			flex-direction: column;
			&--title {
				margin-top: 1rem;
				font-weight: bold;
			}
		}
	}
	&__survey {
		&__language-selector {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			margin: 1rem 0;
			max-width: 45rem;
		}
		&__content {
			// border: 2px solid black;
			// border-radius: 4px;
			&__section {
				border: 2px solid black;
				border-radius: 4px;
				margin: 2rem 0;
				padding: 2rem;
				&__columns {
					display: flex;
					flex-direction: row;
					&--item {
						margin-right: 0.25rem;
					}
				}
				&__rows {
					display: flex;
					flex-direction: column;
					&--item {
						margin-bottom: 0.25rem;
					}
				}
				&__button-container {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					margin-top: 1rem;
					width: 100%;
				}
				&__input-container {
					margin-left: 1rem;
					p {
						color: $orange;
						margin: 0.5rem 0;
					}
				}
			}
		}
	}
}
