/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 4.3em;
	min-width: 4em;
	height: 2.5em;
	margin-right: 1em;
	color: transparent;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
	&.disabled-slider {
		cursor: not-allowed;
		background-color: #6f6c6c;
	}
}

.slider:before {
	position: absolute;
	content: '';
	height: 1.9em;
	width: 1.9em;
	left: 0.3em;
	bottom: 0.3em;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: $orange;
}

input:focus + .slider {
	box-shadow: 0 0 0.1rem $orange;
}

input:checked + .slider:before {
	transform: translateX(1.6em);
}

/* Rounded sliders */
.slider.round {
	border-radius: 2em;
}

.slider.round:before {
	border-radius: 50%;
}
