.tooltip-text {
	@include normal-text($white);
	line-height: 1.2em;
}
.fa-times-square {
	color: $white;
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}
