.template__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: $white;
	position: relative;
	width: 100%;
	padding: 2rem;
	gap: 2rem;
	border-radius: $small-border-radius;
	@include gray-border();
	&:nth-child(even) {
		margin-right: 0;
	}

	.icon-container {
		position: absolute;
		top: 1rem;
		right: 1rem;
		.fa-question-circle {
			color: $orange;
		}
	}
	.template-title {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
		width: 100%;
		&-text {
			@include header-2-text();
			// font-size: 1.4rem;
			text-align: center;
			font-weight: bold;
			white-space: pre-line;
		}
	}
	.template-button-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 1rem;
		width: 100%;
		.button-create-container {
			.button-create {
				background-color: $orange;
				border-radius: 2rem;
				&-disabled {
					background-color: $gray;
					cursor: not-allowed;
					width: 100%;
					border-radius: 2rem;
					&:hover {
						opacity: 1;
					}
				}
			}
			.button-text {
				@include header-5-text($white);
			}
		}

		.button-preview-container {
			.button-preview {
				width: 100%;
				border-radius: 2rem;
				&-icon {
					color: $white;
				}
			}
			.button-text {
				@include header-5-text($white);
			}
		}
		.button-edit {
			background-color: $green;
		}
	}
}
