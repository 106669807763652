div.MuiLinearProgress-root {
	height: 1rem;
}

div.MuiLinearProgress-colorPrimary {
	background-color: $white;
}

div.MuiLinearProgress-barColorPrimary {
	background-color: $orange;
}
