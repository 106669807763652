.term-of-service {
	height: 100%;
	&__header {
		width: 100%;
		height: 30%;
		max-height: 38rem;
	}
	&__section {
		@include container-padding(10rem);
	}
	.button-download {
		padding: 1rem 0;
		&-text {
			@include normal-text($black);
			text-decoration: underline;
		}
	}
}
