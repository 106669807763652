.email-preview {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	background-color: $darker-white;
	&-header {
		padding: 1.5rem 3rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		div &-left {
			flex: 1;
			&-button-container {
				max-width: 16rem;
			}
			&-button {
				border: 0.2rem solid $white;
			}
			&-button-text {
				font-family: $cabin;
				color: $white;
				font-size: $normal-biggest-font-size;
			}
			.fa-long-arrow-left {
				color: $white;
				margin-right: 0.7rem;
			}
		}
		&-center {
			display: flex;
			justify-content: center;
			&-text {
				@include header-2-text($white);
			}
		}
		&-right {
			flex: 1;
			display: flex;
			justify-content: flex-end;
			&-icon {
				margin-right: -1rem;
				padding: 1rem;
				&:hover {
					cursor: pointer;
				}
				.fa-times {
					color: $white;
				}
			}
		}
	}
	&-inner-container {
		@include container-padding-big(4rem);
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		width: 100%;
	}
	&-email-header {
		display: flex;
		align-items: center;
		background-color: $white;
		padding: 1em;
		margin-bottom: 3rem;
	}
	&-content {
		border-top: 0.2rem solid $orange;
		padding: 3rem 2rem;
		background-color: white;
		width: 100%;
		margin-bottom: 3rem;
		&-text {
			margin-bottom: 3rem;
			height: auto;
			white-space: pre-wrap;
		}
		.email-preview-bold-text {
			@include normal-bold-text($black);
			margin-bottom: 2rem;
		}

		.email-preview-orange-button {
			max-width: 50%;
			background-color: $orange;
		}
		.email-preview-orange-button-text {
			font-size: $normal-biggest-font-size;
		}
	}
	&-footer {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&-normal-text {
		@include normal-text($black);
	}
	&-normal-bold-text {
		@include normal-bold-text($black);
		margin-right: 1rem;
	}
	&-upper-logo {
		height: 2.6rem;
		width: 7rem;
		margin: 4rem 0;
		align-self: center;
	}
	&-logo {
		height: 2.6rem;
		width: 7rem;
		margin: 1.2rem 0;
		align-self: center;
	}
}
