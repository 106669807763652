div.paragraph-template {
	padding: $template-padding;
	margin-bottom: 3rem;
	//Override style
	.paragraph-textarea {
		width: 100%;
		font-weight: normal;
		.MuiInputBase-input.Mui-disabled {
			border-color: $gray;
		}
	}
}
