.organisation-item {
	display: flex;
	padding: 0.6rem 4.5rem;
	justify-content: space-between;
	align-items: center;
	$self: &;
	&-left {
		flex: 2;
		display: flex;
		justify-content: space-between;
	}
	&-right {
		flex: 1;
		display: flex;
		justify-content: flex-end;

		> .button:not(:last-child) {
			margin-right: 1rem;
		}
		.organisation-item__modify-organisation {
			button {
				background-color: $orange;
			}

			.button-icon .organisation-item-blue-button-text {
				padding: 0.6rem 0.9rem;

				.icon {
					margin: unset;
				}
			}
		}
	}
	&-white {
		background-color: $white;
	}
	&-gray {
		background-color: $darker-white;
	}
	&-normal-text {
		@include normal-text($black);
		display: flex;
		flex: 1;
		flex-wrap: nowrap;
		align-items: center;
	}
	&-span-text {
		@include normal-text($black);
		display: flex;
		flex: 2;
		flex-wrap: nowrap;
		align-items: center;
	}
	&-bold-text {
		margin-left: 0.5rem;
		text-align: left;
		@include normal-bold-text($black);
	}
	#{$self}-blue-button {
		&-text {
			font-family: $montserrat;
			font-size: $smaller-font-size;
			padding: 0.6rem 2.8rem;
		}
	}

	&-gray-button-container {
		margin-right: 1.5rem;
		#{$self}-gray-button {
			background-color: $gray;
			&-text {
				font-family: $montserrat;
				font-size: $smaller-font-size;
				padding: 0.6rem 2.8rem;
			}
		}
	}
}
