body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
	outline: none;
}

button {
	border: 0;
	background-color: transparent;
	padding: 0;
	&:hover {
		cursor: pointer;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 1;
	}
}
// To prevent from icon to be clipping
.fa-2x {
	padding: 0.2em 0;
}
