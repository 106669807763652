.chart-result {
	background-color: $white;
	padding: 0 0 2rem;
	&__header {
		padding-bottom: 1.2rem;
		border-bottom: 0.1rem solid $border-white;
		margin-bottom: 5rem;
		@media print {
			margin-bottom: 2rem;
		}
		.text-wrapper {
			width: 50%;
		}
		&-upper {
			display: flex;
			flex-direction: row;
			padding-bottom: 1.2rem;
			line-height: 4em;
			justify-content: space-between;
			align-items: center;
			flex-wrap: wrap;
		}
		&-right {
			margin-left: 2rem;
			display: flex;
			align-items: center;
			> .switch-wrapper {
				margin-left: 2rem;
			}
		}
	}
	.switch-wrapper {
		display: flex;
		align-items: center;
		.switch-text {
			@include smaller-text($light-black);
			text-transform: uppercase;
			font-weight: bold;
		}
	}
	&__footer {
		margin-top: 5rem;
		padding-top: 1rem;
		border-top: 0.1rem solid $border-white;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:hover {
			cursor: pointer;
		}
		&-row-left {
			display: flex;
			align-items: center;
		}
		.fa-caret-right,
		.fa-caret-up {
			color: $orange;
			margin-right: 1rem;
		}
		&-row-text {
			@include normal-text($orange);
		}
		& &-row-button-orange {
			background-color: $orange;
			&-text {
				font-family: $montserrat;
				font-size: $smaller-font-size;
				font-weight: bold;
				padding: 0.6rem 2rem;
			}
		}
		&-row-end {
			justify-content: flex-end;
		}
		.print-drop-down {
			top: 3rem;
			&-container {
				z-index: 2;
			}
			.dropdown-text {
				font-size: $normal-font-size;
				text-transform: uppercase;
			}
			.dropdown-item {
				min-width: 20em;
			}
		}
	}
	.other-wrapper {
		margin-top: 5rem;
		.other-title {
			color: $light-black;
		}
		.other-content-wrapper {
			padding-left: 0;
		}
		.other-content-text {
			color: $light-black;
		}
	}
}
