select {
	&:focus {
	}
}
.dropdown-preview-value {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.clear-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 1rem;
	width: 3em;
	height: 3em;
	z-index: 100;
	display: flex;
	justify-content: flex-end;
}
.fa-times {
	color: $light-black;
}
