.question__container {
	.question-header {
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: space-between;
		.question-header-title-text {
			width: 100%;
			margin-right: 1em;
			white-space: pre-line;
		}
		.question-text-area {
			width: 100%;
			margin-right: 1em;
			margin-bottom: 1.2rem;
			.MuiInputBase-input {
				border-width: 0;
				padding-left: 0;
				padding-top: 0.25em;
				padding-bottom: 0.25em;
				@include normal-text($light-black);
				&:focus {
					border-bottom: 0.1rem solid $orange;
					transition: $transition-default;
				}
				&::placeholder {
					@include normal-text($gray);
				}
			}
		}
	}
	.question-required-text {
		@include normal-text($red);
	}
	.changing-order-button {
		background-color: $orange;
		@include button-default();
		text-transform: none;
		width: 16rem;
		span {
			padding: 0.6rem 0;
			@include small-text($white);
		}
	}
}

i.select-icon {
	color: $orange;
	width: 2em;
}
