.board-wrapper__container {
	border-top: 0.2rem solid $orange;
	background-color: $white;
	padding: 2rem 3rem 6rem 3rem;
	.board-wrapper-title {
		@include header-2-text($light-black);
		margin-bottom: 1rem;
	}
	.board-wrapper-content {
		@include normal-text($light-black);
		white-space: pre-line;
	}
}
