.container__background {
	width: 100%;
	height: 100%;
	min-height: calc(100vh - 12.8rem);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
	background: url('../assets/images/main-background.png');
	background-blend-mode: multiply;
	background-size: cover;
	.box-container {
		@include box-shadow-default(0, 0.6rem, 2rem, 0, rgba(0, 0, 0, 0.16));
		width: 50%;
		max-height: 100%;
		background-color: $white;
		padding: 3em 5em;
		display: flex;
		flex-direction: column;
		align-items: center;
		.box-title {
			@include header-2-text($orange);
			margin-bottom: 1rem;
			text-align: center;
		}
		.box-content {
			@include normal-text($black);
			margin-bottom: 0.5rem;
			text-align: center;
		}
		.register-link {
			font-size: $normal-font-size;
			margin: 0;
			margin-bottom: 1.5rem;
			text-decoration: none;
		}
		.box-image {
			width: 50%;
			min-width: 20rem;
			min-height: 20rem;
			height: 50%;
		}
	}
}
