.summary-item__container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: $white;
	padding: 2rem;
	position: relative;
	gap: 2rem;
	@include box-shadow-default(0, 0, 20, 0, rgba(0, 0, 0, 0.05));
	flex: 0 1 100%;
	max-width: 100%;
	margin-bottom: 2%;
	border-radius: $small-border-radius;
	@include gray-border();
	@include breakpoint(tab-port) {
		flex: 0 1 49%;
		max-width: 49%;
		margin-right: 2%;
		&:nth-child(even) {
			margin-right: 0;
		}
	}
	@include breakpoint(tab-land) {
		flex: 0 1 32%;
		max-width: 32%;
		margin-right: 2%;
		&:nth-child(even) {
			margin-right: 2%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
	@include breakpoint(desktop) {
		flex: 0 1 23.5%;
		max-width: 23.5%;
		margin-right: 2%;
		&:nth-child(3n) {
			margin-right: 2%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
	.summary-item-title {
		@include header-2-text($black);
	}
	.summary-item-footer {
		display: flex;
		justify-content: space-between;
	}
	.summary-item-address {
		@include normal-text($medium-gray);
	}
	.summary-item-footer-right {
		display: flex;
		align-items: center;

		.summary-item-amount {
			@include normal-text($medium-gray);
			font-weight: 500;
		}
	}
	.summary-item-footer-actions {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		.summary-item-icon-container {
			padding: 0 2rem;
			margin-right: -2rem;
			&:hover {
				cursor: pointer;
				transition: $transition-default;
			}
			.fa-ellipsis-v {
				color: $black;
			}
		}
	}
}
