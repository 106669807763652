.terms-paragraph {
	display: flex;
	flex-direction: column;
	white-space: pre-line;
	font-size: $smaller-font-size;
	margin-bottom: 2em;
	&__text {
		display: inline-block;
		@include smaller-text($black);
		font-weight: normal;
		margin-top: 2em;
	}
	&__link {
		@include smaller-text($dark-blue);
		margin-left: 0.5rem;
	}
}
