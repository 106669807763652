.input-modal {
	&__container {
		background-color: $white;
	}
	&__header {
		display: flex;
		padding: 2rem 3rem 1.2rem 3rem;
		justify-content: space-between;
		border-bottom: 0.2rem solid $border-white;
		&-text {
			color: $light-black;
		}
		&-clear {
			margin-top: 0.5rem;
			&:hover {
				cursor: pointer;
			}
			.fa-times {
				color: $light-black;
			}
		}
	}
	&__content {
		padding: 2rem 3rem;
		&-normal-text {
			color: $light-black;
		}
		& &-text-area {
			width: 100%;
		}
	}
	&__footer {
		display: flex;
		justify-content: center;
		padding: 2rem 3rem;
		&-button-container {
			width: 16em;
		}
		&-button-text {
			font-size: $normal-biggest-font-size;
		}
		& &-button-orange {
			background-color: $orange;
		}
		& &-button-gray {
			background-color: $gray;
		}
	}
	&__left-item {
		margin-right: 1.6rem;
	}
}
