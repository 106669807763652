.smiley-preview {
	padding: 10%;
	.MuiFormGroup-root {
		justify-content: space-between;
	}
	&-option {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		outline: none;
		flex: 1;
		&:hover {
			cursor: pointer;
		}
		&-text {
			font-weight: bold;
			flex-wrap: nowrap;
		}
		.fa-frown {
			color: $orange;
		}
		.fa-meh {
			color: $light-orange;
		}
		.fa-smile {
			color: $light-green;
		}
		.fa-grin {
			color: $green;
		}
		&-icon {
			font-size: 4rem;
			margin-bottom: 2rem;
		}
	}
}
