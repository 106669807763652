.form-category {
	background-color: $darker-white;
	&-inner-container {
		@include container-padding(4rem);
	}
	&-header {
		&-left {
			display: flex;
			align-items: center;
			.fa-long-arrow-alt-left {
				color: $white;
				margin-right: 1rem;
			}
		}
		&-left &-button {
			display: flex;
			align-items: center;
			background-color: $white;
			&:hover {
				cursor: pointer;
			}
			&-text {
				font-family: $montserrat;
				font-size: $smaller-font-size;
				font-weight: bold;
				color: $dark-blue;
				padding: 0.6rem 1.6rem;
			}
			.fa-long-arrow-alt-left {
				font-size: $normal-big-font-size;
			}
		}
		&-back-button {
			display: flex;
			align-items: center;

			&:hover {
				cursor: pointer;
			}
			.fa-long-arrow-alt-left {
				font-size: $normal-big-font-size;
			}
		}
		&-text {
			@include header-2-text($white);
			margin-right: 2rem;
		}

		&-separator {
			background-color: $white;
			width: 0.2rem;
			height: 3rem;
			margin-right: 1.5rem;
		}
	}
}
