.info-line {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	padding: 0.5rem 0;
	margin-bottom: 0.5rem;
	border-bottom: 0.05rem solid $border-white;
	&-left-text {
		@include normal-text($black);
	}
	&-right-text {
		@include normal-bold-text($black);
		font-weight: 600;
		text-align: right;
	}
}
