.text__area {
	width: 100%;
	.MuiInputBase-input {
		@include normal-text();
		line-height: 1.2em;
		padding: 1em;
		border: 0.05rem solid $light-black;
		&:focus {
			border-color: $orange;
		}
		&::placeholder {
			@include normal-text($gray);
		}
	}
	&-error {
		@include normal-text($red);
	}
}
