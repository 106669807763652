.image-modal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	img {
		margin: 2rem;
		width: 20rem;
		@include breakpoint(tab-land) {
			width: 40rem;
		}
		@include breakpoint(desktop) {
			width: 60rem;
		}
	}

	&__close {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		i {
			font-size: 2rem;
			margin: 1.5rem;
			cursor: pointer;
		}
	}
}
