.oval-text {
	background-color: $ming-blue;
	border-radius: 1.3rem;
	p {
		@include smaller-text($white);
		font-weight: bold;
		padding: 0.6rem 1.6rem;
		text-transform: uppercase;
	}
}
