.close-query-alert {
	.button-negative {
		background-color: $gray;
		width: 10em;
		margin-right: 1rem;
	}
	.button-positive {
		background-color: $orange;
		width: 10em;
	}
	.button-text {
		font-size: $small-font-size;
		font-family: $montserrat;
	}
}
