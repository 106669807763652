.terms-header {
	@include normal-bold-text($black);
	& &__order {
		margin-right: 1rem;
		display: inline;
	}
	&__text {
		display: inline;
		margin-bottom: 1rem;
	}
}
