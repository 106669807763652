.result-table {
	padding-top: 3rem;
	border-top: 0.1rem solid $border-white;
	.table-header {
		display: flex;
		align-items: center;
		padding-bottom: 0.5rem;
		&-order {
			@include normal-text($orange);
			width: 3em;
		}
		&-main {
			display: flex;
			flex: 1;
			&-title {
				width: 20%;
				@include breakpoint(desktop-large) {
					width: 30%;
				}
				padding: 1rem 0 1rem 1rem;
				&-text {
					@include normal-text($light-black);
				}
			}
		}
		.row-header {
			flex: 1;
			display: flex;
			flex-direction: column;
			&-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			&-count {
				@include normal-text($light-black);
				text-align: center;
			}
			&-text {
				text-align: center;
				@include smallest-text($light-black);
				white-space: pre-line;
			}
		}
		&-column {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}
		&-normal-text {
			@include normal-text($light-black);
			text-align: center;
		}
	}
}
