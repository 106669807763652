.dropdown__container {
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 100;
	ul.dropdown-show li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $white;
		padding: 2rem 3rem;
		min-width: 30em;
		z-index: 100;
		@include breakpoint(desktop) {
			min-width: 30em;
		}

		&:hover {
			cursor: pointer;
			background-color: $alto-gray;
		}
		.dropdown-item-text {
			@include header-4-text($light-black);
		}
	}

	ul.dropdown-show {
		z-index: 100;
		display: block;
		@include box-shadow-default(0px, 4px, 20px, 0, rgba(0, 0, 0, 0.2));
		li {
			animation: {
				name: dropdown;
				duration: 200ms;
				timing-function: ease-in-out;
				fill-mode: forwards;
			}
		}
	}

	ul {
		border-radius: 0.4rem;
		position: absolute;
		z-index: 100;
		padding-left: 0;
		background-color: transparent;
	}
	ul.dropdown-hide {
		visibility: hidden;
	}
	.dropdown-separator {
		padding: 0 3rem;
		background-color: $white;
		&-border {
			border-bottom: 0.1rem solid $gray;
		}
	}
}
