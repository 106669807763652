.organisation-form {
	padding: 2rem;
	background-color: $white;
	border-top: 0.2rem solid $orange;
	flex: 1.3;
	$self: &;
	&-title {
		@include header-2-text($black);
		margin-bottom: 2rem;
	}
	&-subtitle {
		@include normal-text($black);
	}
	&-input-title {
		@include normal-bold-text($black);
		margin-bottom: 1rem;
		margin-top: 1rem;
	}
	&-input-row {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		&-start {
			display: flex;
			align-items: flex-start;
			margin-bottom: 1rem;
			&-text {
				@include normal-text($black);
				margin-right: 1rem;
				margin-top: 1.4rem;
			}
		}
		&-text {
			@include normal-text($black);
			margin-right: 1rem;
		}
	}
	.input__container .input-row &-input {
		border-radius: 0;
		border: 0.05rem solid $light-black;
	}
	&-separator {
		height: 0.1rem;
		width: 70%;
		background-color: $darker-white;
		margin: 0.5rem 0 1rem 0;
	}
	&-button-wrapper {
		margin: 1rem 0 2rem;
		display: flex;
		align-items: center;
	}
	&-button-container {
		margin-top: 2rem;
		display: flex;
		align-items: center;
		> .button:not(:last-child) {
			margin-right: 1rem;
		}
	}
	#{$self}-delete-button {
		&-container {
			margin-top: 2rem;
			margin-bottom: 2rem;
			width: 10em;
			margin-right: 1rem;
		}
		background-color: $gray;
		&-text {
			font-size: $smaller-font-size;
			font-family: $montserrat;
			font-weight: bold;
			padding: 0.6rem 1rem;
		}
	}
	.add-button {
		background-color: $orange;
	}

	&__add-new-admin {
		margin-left: auto;
	}
}
