.section-title {
	&-row {
		display: flex;
		width: 100%;
		.tooltip-button {
			padding: 0.5rem;
			margin-left: 0.5rem;
		}
		.fa-question-circle {
			color: $orange;
		}
		&-image-question-toggle {
			display: flex;
			align-items: center;
			i {
				color: $orange;
			}
		}
	}
	&-template {
		display: flex;
		.switch-container {
			margin-right: 1rem;
		}
	}
	&-big-text {
		@include header-2-text($light-black);
		width: 100%;
		margin-right: 0.5rem;
		white-space: pre-line;
	}
}

div.text-area-section-title {
	width: 100%;
	.MuiInputBase-input {
		border-width: 0;
		padding-left: 0;
		padding-top: 0.25em;
		padding-bottom: 0.25em;
		@include header-2-text($light-black);
		&:focus {
			border-bottom: 0.1rem solid $orange;
			transition: $transition-default;
		}
		&::placeholder {
			@include header-2-text($gray);
		}
	}
}
