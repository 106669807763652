.about {
	height: 100%;
	&-header {
		width: 100%;
		height: 30%;
		max-height: 38rem;
	}
	&-section {
		@include container-padding(10rem);
		&-title {
			@include header-2-text($black);
			margin-bottom: 1rem;
		}
		&-description {
			@include normal-text();
			white-space: pre-line;
			margin-bottom: 2rem;
		}
		&-content {
			display: flex;
			flex-direction: column;
			.content-item {
				display: flex;
				margin-bottom: 1rem;
				&-text {
					@include normal-text();
				}
				.fa-circle {
					margin-top: 0.5em;
					color: $orange;
					margin-right: 2rem;
				}
			}
			.about-header {
				font-size: $bigger-font-size;
			}
			.about-paragraph {
				margin: 2rem 0;
				font-size: $normal-font-size;
				font-weight: normal;
				white-space: pre-line;
			}
			.about-link {
				font-size: $normal-font-size;
				margin: 0;
			}
		}
	}
}
