//abstract
@import './abstract/variable';
@import './abstract/mixin';
//base
@import './base/typography';
@import './base/_style.scss';
//
// Stylesheet root file.
//
//@import 'settings';
@import './components/index';
//layout
@import './layout/coreLayout';
@import './layout/nav';
@import './layout/footer';
@import './layout/error-boundary';
@import './layout/loading';
//pages
@import './pages/index';

@font-face {
	font-family: $montserrat, sans-serif;
	font-style: normal;
	//src: url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
}

body {
	margin: 0;
	background-color: $light-gray;
	height: 100%;
}

#app {
	font-family: 'Roboto', sans-serif;
	height: 100%;

	.app__content {
		min-height: 100vh;
		position: relative;
	}

	button {
		font-size: 1rem;
	}
}

@media print {
	body {
		background-color: $white;
	}
	@page {
		background-color: $white;
	}
	.result-table {
		page-break-inside: avoid;
	}
	.print-page-break {
		page-break-after: always;
	}
	.print-table-line {
		page-break-inside: avoid;
	}
	.form-print-section {
		page-break-inside: avoid;
	}
	.chart {
		page-break-inside: avoid;
	}
}
