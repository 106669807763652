.form-survey {
	display: flex;
	flex-direction: column;
	position: relative;
	.alert-cancel-button {
		&-container {
			width: 12em;
			margin-right: 1rem;
		}
		&-text {
			font-size: $normal-biggest-font-size;
		}
	}
	.alert-delete-button {
		background-color: $orange;
		&-container {
			width: 12em;
		}
		&-text {
			font-size: $normal-biggest-font-size;
		}
	}

	&-header {
		@include container-padding-smallest(1rem);
		height: 6rem;
		div.header-left {
			display: flex;
			align-items: center;
			.fa-long-arrow-alt-left {
				color: $white;
				margin-right: 1rem;
			}
			.header-back-button {
				display: flex;
				align-items: center;
				&:hover {
					cursor: pointer;
				}
				.fa-long-arrow-alt-left {
					font-size: $normal-big-font-size;
					color: $medium-gray;
				}
			}
			.header-text {
				@include header-2-text($medium-gray);
				margin-right: 1.5rem;
			}

			.header-separator {
				background-color: $white;
				width: 0.2rem;
				height: 3rem;
				margin-right: 1.5rem;
			}
			button.header-button {
				background-color: $white;
			}
			button .header-button-text {
				font-size: $smaller-font-size;
				font-family: $montserrat;
				color: $dark-blue;
				font-weight: bold;
				padding: 0.6rem 1rem;
			}
		}
		div.header-right {
			display: flex;
			justify-content: flex-end;

			button.button-white {
				background-color: $white;
				margin-right: 2rem;
			}
			button .button-white-text {
				@include header-4-text($light-black);
			}
			button.button-orange {
				background-color: $orange;
			}
			button .button-orange-text {
				@include header-4-text($white);
			}
		}
	}

	&-left {
		width: 100%;
		margin-right: 0;
		@include breakpoint(desktop) {
			margin-right: 1.6rem;
			width: 70%;
		}
	}
	&-right {
		display: flex;
		flex-direction: column;
		width: 30%;
		&-fixed {
			display: flex;
			flex-direction: column;
			position: initial;
			@include breakpoint(desktop) {
				overflow-y: scroll;
				top: 14rem;
				right: 5%;
				bottom: 7rem;
				width: calc((100% - (5% * 2) - 3.2rem) * 0.3);
				position: fixed;
			}
		}
	}
	.warning {
		margin-bottom: 2.5rem;
		&-title {
			padding: 1rem 0;
			background-color: $orange;
			display: flex;
			align-items: center;
			justify-content: center;
			h3 {
				color: $white;
			}
		}
		&-content {
			padding: 1.5rem 2rem;
			background-color: $white;
			p {
				white-space: pre-wrap;
			}
		}
	}
	.form-default-template {
		flex-direction: column;
		padding: 3rem 10%;
	}
	&-inner-container {
		padding: 3rem 5%;
		display: flex;
		flex-direction: column;
		position: relative;

		@include breakpoint(desktop) {
			flex-direction: row;
		}
		input.form-survey-input-title {
			@include header-2-text($light-black);
			height: 1.5em;
			border-radius: 0;
			&:focus {
				border-bottom: 0.1rem solid $orange;
				transition: $transition-default;
			}
			&::placeholder {
				@include header-2-text($gray);
			}
		}
		&.default-meter {
			justify-content: center;
		}
	}
	&__footer {
		display: flex;
		justify-content: center;
		margin-top: 3rem;
		@include breakpoint(desktop) {
			margin-bottom: 3rem;
		}
		.button-save {
			background-color: $orange;
			&:disabled {
				background-color: $gray;
			}
			&-text {
				font-size: $normal-biggest-font-size;
				padding: 0.9rem 3rem;
			}
		}
	}

	// Image as question
	&__attach-image {
		&--actions {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: space-around;

			i {
				color: $dark-blue;
				font-size: 2rem;
			}
			span {
				font-size: 1.6rem;
				cursor: pointer;
				white-space: nowrap;
				margin: 0.6rem 0;
			}
			.preview {
				max-width: 100px;
				display: flex;
				flex-direction: column;
				overflow: hidden;
			}
		}
		&--selected {
			display: flex;
			flex-direction: column;
			i {
				color: $orange;
			}
			img {
				max-width: 150px;
				margin: 10px 0;
			}
		}
		i {
			margin: 0 0.5rem;
			font-size: 1.4rem;
		}
	}
}
