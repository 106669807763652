.welcome-section {
	display: flex;
	flex-direction: column;
	@include breakpoint(tab-port) {
		flex-direction: row;
	}
	@include container-padding(6rem);
	&-white {
		background-color: $white;
	}
	&-gray {
		background-color: $light-white-gray;
	}
	&-image {
		width: 80%;
		align-self: center;
		margin-bottom: 3rem;
		@include breakpoint(tab-port) {
			width: 40%;
		}
		height: auto;
	}
	&-title {
		@include header-2-text($black);
		margin-bottom: 1rem;
	}
	&-description {
		@include normal-big-text();
		margin-bottom: 2rem;
	}
	&-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		&-left {
			margin-left: 3rem;
		}
		&-right {
			margin-right: 3rem;
		}
		.content-item {
			display: flex;
			margin-bottom: 1rem;
			&-text {
				@include normal-text();
			}
			.fa-circle {
				margin-top: 0.5em;
				color: $orange;
				margin-right: 2rem;
			}
		}
	}
}
