.not-found {
	display: flex;
	justify-content: center;
	align-items: center;
	background: url('../assets/images/main-background.png');
	background-blend-mode: multiply;
	background-size: cover;
	width: 100%;
	margin-top: -4rem;
	height: calc(100vw * 9 / 16);
	margin-bottom: -5.8rem;
	.box-container {
		@include box-shadow-default(0, 0.6rem, 2rem, 0, rgba(0, 0, 0, 0.16));
		width: 50%;
		max-height: 100%;
		background-color: $white;
		padding: 3em 5em;
		display: flex;
		flex-direction: column;
		align-items: center;
		.box-title {
			@include header-2-text($orange);
			margin-bottom: 4rem;
			text-align: center;
		}
		.box-content {
			@include normal-text($black);
			margin-bottom: 4rem;
			text-align: center;
		}
		.box-image {
			width: 50%;
			min-width: 20rem;
			min-height: 20rem;
			height: 50%;
		}
	}
}
