.print-answers__container {
	display: flex;
	flex-direction: column;
	&-section {
		border-left-width: 0;
		&-title {
			@include header-2-text($light-black);
			white-space: pre-line;
		}
	}
	.section__container {
		border-left: none;
	}
	@media print {
		.nav,
		.footer__container,
		.clear-answer {
			display: none;
		}
		&-section {
			min-height: 264mm;
		}
	}
}
