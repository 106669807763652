//color
$orange: var(--primary-color);
$light-orange: #f4bf6c;
$lighter-orange: #fc4f0066;
$orange-white: #f4f0e4;
$white: #fff;
$white-light-gray: #f0f0f0;
$dark-white: #fbf7f5;
$light-black: #3d4640;
$black: #000;
$darker-gray: #18191a;
$dark-blue: #00325a;
$normal-blue: #0c10b3;
$light-blue: #6478d3;
$eastern-blue: #2c9ab7;
$dark-gray: #9d9d9d;
$medium-gray: #494b4d;
$ming-blue: #3d6385;
$green-blue: #9bcec2;
$gray: #9d9d9d;
$button-gray: #dfe3e5;
$lighter-gray: #f7f7f7;
$light-white-gray: #f8f8f8;
$light-gray: #f6f6f6;
$alto-gray: #d3d3d3;
$dark-alto-gray: #d7d7d7;
$white-gray: #ededed;
$darker-white: #f5f5f5;
$red: red;
$green: #279a7f;
$light-green: #9bcec2;
$border-white: #f1f3f8;

//font-family:
$bebas-neue: 'bebas-neue';
$montserrat: 'Montserrat';
$cabin: 'Cabin';

//font-size:
$smallest-font-size: 0.9rem;
$small-smaller-font-size: 1rem;
$smaller-font-size: 1.1rem;
$small-font-size: 1.2rem;
$normal-font-size: 1.4rem;
$normal-big-font-size: 1.6rem;
$normal-biggest-font-size: 1.8rem;
$big-font-size: 2rem;
$bigger-font-size: 2.2rem;
$biggest-font-size: 2.6rem;
$big-title-font-size: 3.8rem;
$biggest-title-font-size: 7.6rem;

//transition
$transition-default: all 0.4s;

//border
$border-active: 0.1rem solid $light-orange;

//border-radius: (button)
$normal-border-radius: 2rem;
$small-border-radius: 1rem;
$smaller-border-radius: 0.8rem;

//Padding, margin
$template-padding: 2rem 0;
