.terms-content {
	&__title {
		margin-bottom: 2rem;
		font-size: $bigger-font-size;
	}
	&__big-title {
		font-size: $biggest-font-size;
	}
	&__subtitle {
		margin-bottom: 1.2rem;
		font-size: $smaller-font-size;
	}
	&__big-subtitle {
		font-size: $normal-font-size;
	}
	&__header-list {
		list-style: none;
		margin-left: -4rem;
	}
	&__normal-text {
		font-size: $normal-font-size;
	}
	&__big-header {
		font-size: $bigger-font-size;
	}
}
