.nav {
	display: flex;
	flex-direction: row;
	background-color: $white;
	height: 7em;
	z-index: 100000000;
	@include nav-padding(2rem);
	&-hide {
		visibility: hidden;
		height: 0;
	}
	width: 100%;
	&__left {
		display: flex;
		flex-direction: row;
		@include breakpoint('tab-land') {
			flex: 3;
		}
		justify-content: flex-start;
		align-items: center;
	}
	.nav__user-focus {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: $white;
		z-index: 1000000001; //above modal
		padding: 0 1em;
		height: 6rem;
		width: 6rem;
		margin-top: -1.5rem;
		border-radius: 50%;
		position: relative;
		margin-right: 2rem;
	}
	.nav__user {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 1rem;
		@include breakpoint('phone') {
			padding: 1em;
		}
		position: relative;
		&:hover {
			cursor: pointer;
			opacity: 0.7;
		}
	}
	.nav__user-name {
		font-family: $montserrat;
		color: $black;
		font-size: $normal-big-font-size;
		padding: 0.5em;
		@include breakpoint('phone') {
			padding: 0;
		}
	}
	.nav-user-image {
		position: absolute;
		bottom: -10rem;
		left: -4rem;
	}
	&__right {
		display: flex;
		flex-direction: row;
		flex: 1;
		justify-content: flex-end;
		align-items: center;
		z-index: 100000000;
		&--language-selector {
			margin-left: 2rem;
			&--custom {
				border-radius: 25px;
				.MuiSelect-select,
				.MuiSelect-select:focus {
					background-color: $white;
				}
			}
		}
		.nav-guide-button-text {
			padding: 0.9rem 3rem;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: $normal-biggest-font-size;
			color: $medium-gray;
		}
		.nav-button-guide-container {
			z-index: 1;
		}
		.dropdown {
			top: 4rem;
			left: -75%;
			@include box-shadow-default();
		}
		& .guide-icon {
			color: $medium-gray;
			font-size: 1.6rem;
			margin-left: 1rem;
		}
	}
	button .nav-button-sign-in-text {
		padding: 0.9rem 5rem;
	}
	&__logo {
		width: auto;
		height: 2.1rem;
		@include breakpoint('phone') {
			max-width: 6rem;
		}
		@include breakpoint('tab-land') {
			max-width: 100%;
			height: 4.2rem;
		}
		margin-right: 2em;
		&-wrapper {
			width: 14.8rem;
			@include breakpoint('phone') {
				max-width: 6.5rem;
			}
			&:hover {
				cursor: pointer;
				transition: $transition-default;
			}
		}
	}
	&__avatar {
		width: 2em;
		height: 2em;
		border-radius: 50%;

		+ .nav__unread-notification {
			position: absolute;
			left: 1.25rem;
			top: -0.75rem;
			background: $orange;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1rem;
			font-weight: bold;
			color: white;
			height: 1.5rem;
			width: 1.5rem;
		}
	}
	&-burger-button {
		display: flex;
		align-items: center;
		padding: 1rem;
		margin-left: 1rem;
		&:hover {
			cursor: pointer;
		}
		@include breakpoint('phone') {
			margin-left: 0.5rem;
			flex-basis: 17.5%;
		}
		@include breakpoint('tab-land') {
			display: none;
		}
	}
}
