#small-item {
	width: 30%;
	@include breakpoint('tab-land') {
		width: 10%;
	}
}
#big-item {
	width: 40%;
	@include breakpoint('tab-land') {
		width: 80%;
	}
}
.notification-item {
	padding: 1.2rem 3rem;
	background-color: $white;
	position: relative;
	width: 100%;
	border-bottom: 0.05rem solid $border-white;
	&__summary {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	&__message {
		margin-top: 1rem;
	}
	&__content {
		display: flex;
		flex-direction: row;
		width: 100%;
	}
	&__sender {
		text-align: left;
		@extend #small-item;
		margin-right: 1rem;
	}
	&__title {
		text-align: left;
		@extend #big-item;
		margin-right: 1rem;
	}
	&__date {
		text-align: left;
		@extend #small-item;
		margin-right: 1rem;
	}
	&__pop-up-icon {
		padding: 1rem;
	}
	&__checkbox {
		margin-right: 1rem;
	}
	.bold-text {
		font-weight: bold;
	}
	&__pop-up {
		bottom: -8rem;
		right: 0;
	}
}
