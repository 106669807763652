.checkbox-preview__container {
	padding: $template-padding;
	.checkbox-row {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		.input-row input {
			height: 2em;
			border-radius: 0;
			margin-left: 1rem;
			border-bottom: 0.1rem solid $gray;
		}
	}
	.checkbox-column {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
	}
	.checkbox-label {
		text-align: left;
		@include normal-bold-text($light-black);
	}
}
.checkbox-preview-horizontal {
	display: flex;
	align-items: center;
	justify-content: center;
}
