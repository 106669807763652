.container__background {
	width: 100%;
	height: calc(100vh - 12.8rem);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: url('../assets/images/main-background.png');
	background-blend-mode: multiply;
	background-size: cover;
	.reset-password-modal {
		margin: 10% 20%;
		.alert-button {
			background-color: $orange;
			&-text {
				padding: 0.9rem 5rem;
			}
		}
	}
	.reset-password-input-hint {
		margin: 0 0 1rem 0;
		color: $orange;
		font-style: italic;
	}
	.reset-password-board {
		width: 90%;
		background-color: $white;
		padding: 6rem 2rem;
		display: flex;
		flex-direction: column;
		@include breakpoint(tab-port) {
			width: 60%;
			padding: 6rem 10%;
		}
		.reset-password-title {
			@include header-2-text($orange);
			text-align: center;
			margin-bottom: 2rem;
		}
		.reset-password-subtitle {
			@include normal-text($black);
			text-align: center;
		}
		.reset-password-input-title {
			@include normal-bold-text($black);
			margin-top: 4rem;
			margin-bottom: 1rem;
		}
		.reset-password-button-send {
			background-color: $orange;
			max-width: 20em;
			&-disabled {
				background-color: $gray;
				&:hover {
					cursor: default;
					opacity: 1;
				}
			}
			&-text {
				font-size: $normal-biggest-font-size;
			}
		}
		.reset-password-button-send-container {
			margin-top: 2rem;
		}
		.reset-password-input {
			border-color: $light-black;
		}
	}
}
