@mixin button-default($background-color: $orange) {
	display: flex;
	background-color: $background-color;
	justify-content: center;
	align-items: center;
	text-transform: none;
	transition: $transition-default;
	border: 0;
	border-radius: 2rem;
	// width: 100%;
	&:hover {
		cursor: pointer;
		opacity: 0.7;
	}
	&:disabled {
		cursor: not-allowed;
		opacity: 1;
	}
}

@mixin button-disabled-default($background-color: $gray) {
	background-color: $background-color;
	&:hover {
		cursor: default;
		opacity: 1;
	}
}

@mixin button-container-default(
	$flex-direction: row,
	$justify-content: center,
	$align-items: center
) {
	display: flex;
	flex-direction: $flex-direction;
	justify-content: $justify-content;
	align-items: $align-items;
}

//Text
@mixin header-2-text($color: $black, $transform: none) {
	font-size: $bigger-font-size;
	font-family: $cabin;
	color: $color;
	text-transform: $transform;
}

@mixin header-2-text($color: $black, $transform: none) {
	font-size: $big-font-size;
	font-family: $cabin;
	color: $color;
	text-transform: $transform;
}

@mixin header-3-text($color: $black, $transform: none) {
	font-size: $normal-biggest-font-size;
	font-family: $cabin;
	color: $color;
	text-transform: $transform;
}

@mixin header-4-text($color: $black, $transform: none) {
	font-size: $normal-big-font-size;
	font-family: $cabin;
	color: $color;
	text-transform: $transform;
}

@mixin header-5-text($color: $black, $transform: none) {
	font-size: $normal-font-size;
	font-family: $cabin;
	color: $color;
	text-transform: $transform;
}

@mixin header-6-text($color: $black, $transform: none) {
	font-size: $normal-font-size;
	font-family: $cabin;
	color: $color;
	text-transform: $transform;
}

@mixin normal-big-text($color: $black) {
	font-size: $normal-big-font-size;
	font-family: $montserrat;
	color: $color;
}
@mixin normal-text($color: $black) {
	font-size: $normal-font-size;
	font-family: $montserrat;
	color: $color;
}

@mixin normal-bold-text($color: $black) {
	@include normal-text($color);
	font-weight: bold;
}

@mixin small-text($color: $black) {
	font-size: $small-font-size;
	font-family: $montserrat;
	color: $color;
}

@mixin smaller-text($color: $black, $font-weight: 400) {
	font-size: $smaller-font-size;
	font-family: $montserrat;
	color: $color;
	font-weight: $font-weight;
}

@mixin small-smaller-text($color: $black, $font-weight: 400) {
	font-size: $small-smaller-font-size;
	font-family: $montserrat;
	color: $color;
	font-weight: $font-weight;
}

@mixin smallest-text($color: $black, $font-weight: 400) {
	font-size: $smallest-font-size;
	font-family: $montserrat;
	color: $color;
	font-weight: $font-weight;
}

//custom button
@mixin small-button($color: $orange, $text-color: $white) {
	background-color: $color;
	&-text {
		padding: 0.6rem 1rem;
		@include smaller-text($text-color);
	}
}
//box-shadow
@mixin box-shadow-default(
	$x: 0,
	$y: 4px,
	$blur: 20px,
	$spread: 0px,
	$color: rgba(0, 0, 0, 0.1)
) {
	box-shadow: $x $y $blur $spread $color;
}

%error_message {
	color: $red;
	font-size: $normal-font-size;
	font-family: $montserrat;
}

// Border
@mixin gray-border($width: 1px, $style: solid, $color: $button-gray) {
	border: $width $style $color;
}

//Break point
/**
 * Breakpoints
 *
 * 1em = 16px
 * phone
 * tab-port
 * tab-land
 * desktop
 * big-desktop
 */
@mixin breakpoint($view) {
	@if ($view == small-phone) {
		@media (max-width: 25em) {
			@content; // 400px
		}
	}

	@if ($view == phone) {
		@media (max-width: 37.5em) {
			@content; // 600px
		}
	}

	@if ($view == tab-port) {
		@media (min-width: 37.5em) {
			@content; // 600px
		}
	}

	@if ($view == tab-land) {
		@media (min-width: 56.25em) {
			@content; // 900px
		}
	}

	@if ($view == desktop) {
		@media (min-width: 75em) {
			@content; // 1200px
		}
	}

	@if ($view == desktop-medium) {
		@media (min-width: 75em) {
			@content; // 1200px
		}
	}

	@if ($view == desktop-large) {
		@media (min-width: 112.5em) {
			@content; // 1800px
		}
	}
}

//container
@mixin container-padding($padding-vertical: 0) {
	padding: $padding-vertical 1.6rem;
	box-sizing: border-box;
	@include breakpoint('tab-land') {
		padding: $padding-vertical 10%;
	}
	@include breakpoint('desktop') {
		padding: $padding-vertical 20%;
	}
	@include breakpoint('desktop-large') {
		padding: $padding-vertical 25%;
	}
}

@mixin container-padding-small($padding-vertical: 0) {
	padding: $padding-vertical 1.6rem;
	box-sizing: border-box;
	@include breakpoint('tab-land') {
		padding: $padding-vertical 10%;
	}
	@include breakpoint('desktop-large') {
		padding: $padding-vertical 20%;
	}
}

@mixin container-padding-big($padding-vertical: 0) {
	padding: $padding-vertical 1.6rem;
	box-sizing: border-box;
	@include breakpoint('tab-port') {
		padding: $padding-vertical 15%;
	}
	@include breakpoint('tab-land') {
		padding: $padding-vertical 20%;
	}
	@include breakpoint('desktop') {
		padding: $padding-vertical 30%;
	}
	@include breakpoint('desktop-large') {
		padding: $padding-vertical 35%;
	}
}

@mixin container-padding-smallest($padding-vertical: 0) {
	padding: $padding-vertical 1.6rem;
	box-sizing: border-box;
	@include breakpoint('tab-land') {
		padding: $padding-vertical 5%;
	}
	@include breakpoint('desktop-large') {
		padding: $padding-vertical 10%;
	}
}

@mixin container-padding-extra-smallest($padding-vertical: 0) {
	padding: $padding-vertical 1.6rem;
	box-sizing: border-box;
	@include breakpoint('tab-land') {
		padding: $padding-vertical 3.2rem;
	}
	@include breakpoint('desktop-large') {
		padding: $padding-vertical 3.2rem;
	}
}

@mixin container-margin($padding-vertical: 0) {
	margin: $padding-vertical 1.6rem;
	box-sizing: border-box;
	@include breakpoint('tab-land') {
		margin: $padding-vertical 10%;
	}
	@include breakpoint('desktop') {
		margin: $padding-vertical 20%;
	}
	@include breakpoint('desktop-large') {
		margin: $padding-vertical 25%;
	}
}

@mixin container-margin-big() {
	margin-left: 10%;
	margin-right: 10%;
	box-sizing: border-box;
	@include breakpoint('tab-land') {
		margin-left: 15%;
		margin-right: 15%;
	}
	@include breakpoint('desktop') {
		margin-left: 25%;
		margin-right: 25%;
	}
	@include breakpoint('desktop-large') {
		margin-left: 30%;
		margin-right: 30%;
	}
}

//Header and footer padding
@mixin nav-padding($padding-vertical: 0) {
	padding: $padding-vertical 1.6em;
	@include breakpoint('tab-land') {
		padding: $padding-vertical 7em;
	}
	@include breakpoint('desktop') {
		padding: $padding-vertical 14em;
	}
}

//animations
@keyframes dropdown {
	0% {
		opacity: 0;
		transform: rotateY(0) translateY(3rem);
	}
	100% {
		opacity: 1;
		transform: rotateY(0deg) translateY(0);
	}
}

//print

@mixin grayscale {
	filter: gray; /* IE */
	-webkit-filter: grayscale(1); /* Old WebKit */
	-webkit-filter: grayscale(100%); /* New WebKit */
	filter: grayscale(100%); /* Current draft standard */
}
