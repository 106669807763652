.update-modal .modal-container {
	background-color: unset;
	width: 100%;
	height: 100%;
	margin: unset;
	.modal-content {
		transform: translate(-50%, -50%);
		top: 50%;
		padding: 3rem;
		text-align: center;
		width: 30vw;
		left: 50%;

		.reset-button {
			width: unset;
			background-color: $orange;
			margin-top: 1rem;
		}
	}
}
