label.input-with-title {
	.input-title {
		@include normal-bold-text($black);
		margin: 0.5rem 0;
	}
	.input-row .input-input {
		border: 0.05rem solid $light-black;
		border-radius: 0;
		&:focus {
			border-color: $orange;
			transition: $transition-default;
		}
	}
}
