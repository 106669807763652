.input-with-emoji {
	position: relative;
	width: 100%;
	&-emoji {
		position: absolute;
		top: 4em;
		left: 0;
		z-index: 100;
	}
}
