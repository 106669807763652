.template-board {
	background-color: $darker-white;
	flex: 1;
	width: 100%;
	&-title {
		background-color: $dark-blue;
		display: flex;
		align-items: center;
		justify-content: center;
		&-text {
			font-size: $normal-biggest-font-size;
			font-family: $cabin;
			color: $white;
			font-weight: bold;
			padding: 1rem 0;
			text-align: center;
		}
	}
	&-description {
		padding: 2em;
		background-color: white;
		margin-bottom: 0.8rem;
		&-text {
			@include normal-text($black);
		}
	}
	&-content {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		background-color: $darker-white;
		div & &-item-title {
			font-size: $normal-big-font-size;
			line-height: 1.2em;
			letter-spacing: 0.1rem;
			white-space: pre-line;
		}
	}
	& &__item {
		flex: 0 1 49.5%;
		max-width: 49.5%;
		margin-right: 1%;
		margin-bottom: 1%;
		&:nth-child(even) {
			margin-right: 0;
		}
		@include breakpoint(desktop) {
			flex: 0 1 49.5%;
			max-width: 49.5%;
			margin-right: 1%;
			margin-bottom: 1%;
			&:nth-child(even) {
				margin-right: 0;
			}
		}
		@include breakpoint(desktop-large) {
			flex: 0 1 33%;
			max-width: 33%;
			margin-right: 0.5%;
			margin-bottom: 0.5%;
			&:nth-child(even) {
				margin-right: 0.5%;
			}
			&:nth-child(3n) {
				margin-right: 0;
			}
		}
		.template-button-container {
			flex-direction: column;
		}
	}
	& &-modal-content {
		background-color: $darker-white;
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		& .template-board__item {
			flex: 0 1 49.5%;
			max-width: 49.5%;
			margin-right: 1%;
			margin-bottom: 1%;
			&:nth-child(even) {
				margin-right: 0;
			}
			@include breakpoint(desktop) {
				flex: 0 1 33%;
				max-width: 33%;
				margin-right: 0.5%;
				margin-bottom: 0.5%;
				&:nth-child(even) {
					margin-right: 0.5%;
				}
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			@include breakpoint(desktop-large) {
				flex: 0 1 33%;
				max-width: 33%;
				margin-right: 0.5%;
				margin-bottom: 0.5%;
				&:nth-child(even) {
					margin-right: 0.5%;
				}
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
}
