.modal {
	position: fixed;
	z-index: 1000; //above all
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
	&-container {
		background-color: $white;
		margin: 8rem;
		@include box-shadow-default();
		.modal-content {
			position: absolute;
			background-color: $white;
			left: 1.6rem;
			right: 1.6rem;
			@include breakpoint(desktop) {
				left: 20%;
				right: 20%;
			}
		}
		position: relative;
	}
	&-footer {
		position: absolute;
		left: 0;
		bottom: -5rem;
	}
	&::content {
		animation: {
			name: animate-top;
			duration: 0.4s;
		}
	}
}

.show {
	display: block;
}

.hide {
	display: none;
}

/* Add Animation */
@keyframes animate-top {
	from {
		top: -100%;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
