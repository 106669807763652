.copy-button {
	@include button-default($orange);
	width: auto;
	.text {
		padding: 0.6rem 2rem;
		font-family: $montserrat;
		color: $white;
		text-transform: uppercase;
		font-size: $smaller-font-size;
	}
}
