.analysis__template-item {
	width: 100%;
	padding: 2rem;

	.button {
		max-width: 40%;
		button {
			background-color: $orange;
		}
	}
}
